import React from 'react';
import { Link } from "react-router-dom";

class ViewInitial extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    render () {
        return <>
                 <h2>INFINITUS IMOTION NETWORK</h2>   
                 {this.props.at===null ?
                 <p>Please<Link className="dark" to="/login">login</Link>to continue</p>
                 : null }
               </>
    }
}
export default ViewInitial;