import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, useHistory } from "react-router-dom";

import logo from './logo.svg';
import './App.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Profile from './components/Profile.js';
import UserLogin from './components/UserLogin.js';
import Support from './components/Support.js';

import ComLib from './comm/comlib.js';
import ViewInitial from './components/ViewInitial.js';
import NetworkStatus from './components/NetworkStatus.js';
import DeviceViewNetStat from './components/DeviceViewNetStat.js';

import AlarmHistory from './components/AlarmsHistory.js';
import AlarmView from './components/AlarmView.js';
import BarBottom from './components/BarBottom.js';
import BarTop from './components/BarTop.js';
import DevicesList from './components/DevicesList.js';
import DevicesMap from './components/DevicesMap.js';
import DeviceView from './components/DeviceView.js';
import DeviceViewPropertyGraph from './components/DeviceViewPropertyGraph.js'; 
import ViewBSensor from './components/ViewBSensor.js';
import ViewFlow from './components/ViewFlow.js';
import ViewG6 from './components/ViewG6.js';
import ViewDisplayControl from './components/ViewDisplayControl.js';
import Notfound from './components/NotFound.js';
import Art from './components/Art.js';

import CookiesPolicy from './components/pages/CookiesPolicy';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsAndConditions from './components/pages/TermsAndConditions';
import PartnerAccess from './components/pages/PartnerAccess';

class App extends React.Component {
  constructor(props) {
    super(props);
      window.app = this; // quick hack to show messages, not very react-ihs way 
      this.state =  { 
         comm: null, //new ComLib(this),
         accessToken: null,
         redirectToLogin: false,
         redirectToProfile: false,
         userName: null,
         usersSummary: null,
         devices: [],
         selectedDevice: null,
         graphSymbol:"",
         graphTitle:"",
         alarms: null,
         deviceAlarms:null,
         selectedAlarm:null
      }; 
  }
  componentDidMount() { 
    this.init();
    //this.state.comm.setup(); // signalr
  }
  init() {

  }

  setError(s) { 
    if (typeof s === 'string' || s instanceof String) this.setState({error:s}); 
    else this.setState({error:JSON.stringify(s)}); 
  }

  displayToast(text) { 
    toast.error(text, 
    {
      className: 'toast-error',
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  }

  updateConnectionState(state) {
    console.log(this.state.comm.connection.state);
    if(state==="Connected")
    {
      this.state.comm.getUsersSummary(this,this.state.accessToken);
      //this.state.comm.addToGroup(this, this.state.accessToken.userId);
    }
  }

  async Login(user,pass) {
    //this.setState({enabled:false});
    localStorage.setItem("user", user);
    const response = await fetch('https://iotsihelpi.azurewebsites.net/authenticate',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({email: user, password: pass})
        }
    );

    if(response.status===200)
    {
        // waits until the request completes...
        const authToken = await response.json();
        console.log(authToken);
        this.displayToast("Signed in");
        this.signedWith(authToken);
        
    } else {
        this.displayToast("Failed to sign in");
        //this.setState({enabled:true});
    }
  }

  async signedWith(token) {
      this.setState({accessToken: token});
      this.setState({redirectToProfile: true});

      await this.setState({comm: new ComLib(this, {UserId:token.userId, RequestToken: token.requestToken })});
      await this.state.comm.setup(); // signalr
  }
  async signOut()
  {
    console.log("Signing out"); 
    if(this.state.usersSummary!==null)
    {
      for(var i=0;i<this.state.usersSummary.length;i++) // join groups only when 
      {
          this.state.comm.removeFromGroup(this, this.state.usersSummary[i].userId);
      }
    }

    this.setState({redirectToLogin:true});
    this.setState({accessToken:null});
    this.setState({usersSummary:null});
    this.setState({devices:[]});
    this.setState({selectedDevice:null});
    console.log("signed out");
  }

  setUsersSummary(usersSummary) {
    var newDevices = [];
    var uniqueDevices={};
    
    for(var i=0;i<usersSummary.length;i++) // join groups only when 
    {
      if(this.state.usersSummary===null)
         this.state.comm.addToGroup(this, usersSummary[i].userId);

      for(var j=0;j<usersSummary[i].devices.length;j++)
      {
          let d = usersSummary[i].devices[j];
          if(uniqueDevices[d.deviceId]===undefined) 
          {
            d.lastTimeDataProperty = {}; // init time object 
            d.lastTimeData = 0; // init time object 

            newDevices.push(d);
            uniqueDevices[d.deviceId] = "exists";

            this.state.comm.addToGroup(this, d.deviceId);
          }
      }
    }

    this.setState({usersSummary:usersSummary});   
    console.log("setUsersSummary", newDevices);
    this.setState({devices:newDevices});   
  }

  updateDevice(device, setIsOnline) {
    var isSelectedDeviceUpdated = false;

    if(this.state.selectedDevice!=null)
      isSelectedDeviceUpdated = device.deviceId === this.state.selectedDevice.deviceId;

    var oldDevices=this.state.devices; 
    for(let d of oldDevices)
    //oldDevices.forEach(d => 
    {
      if(d.deviceId===device.deviceId)
      {
        if(setIsOnline===true) d.online=1;
        //if(d.lastTimeData===undefined) d.lastTimeDataProperty = {}; // init time object 
        //d.lastTimeData = Date.now();
        
        if(device.iMotionFlow!=null)   { d.iMotionFlow = device.iMotionFlow; }
        if(device.iMotionG6!=null)     { d.iMotionG6 = device.iMotionG6; }
        if(device.beagleSensor!=null)  { d.beagleSensor = device.beagleSensor; }
        if(device.displayControl!=null)  { d.displayControl = device.displayControl; }

        if(isSelectedDeviceUpdated)
            this.setState({selectedDevice:d});
        
        //console.log("selectedDevice", this.state.selectedDevice);
        break;      
      }
    }
    //);

    //console.log("updateDevices", oldDevices);
    this.setState({devices:oldDevices});
  }
  updateDeviceParam(deviceId, propertyName, value)
  {
    //console.log(deviceId,propertyName,value);  
    var isSelectedDeviceUpdated = false;

    if(this.state.selectedDevice!=null)
      isSelectedDeviceUpdated = deviceId === this.state.selectedDevice.deviceId;

    var oldDevices=this.state.devices; 

    var updated = false;
    for(var i=0;i<oldDevices.length;i++)
    {
      var d = oldDevices[i];
      if(d.deviceId===deviceId)
      {
        // console.log("updateDeviceParam", d.name, propertyName, value);
        /*if(setIsOnline===true)*/ d.online=1;
        if(updated === false && this.updatePropertyOn(d.iMotionFlow, propertyName, value)===true) updated = true; //break;
        if(updated === false && this.updatePropertyOn(d.iMotionG6, propertyName, value)===true) updated = true; //break;
        if(updated === false && this.updatePropertyOn(d.beagleSensor, propertyName, value)===true) updated = true; //break;
        if(updated === false && this.updatePropertyOn(d.displayControl, propertyName, value)===true) updated = true; //break;

        if(updated===true)// && isSelectedDeviceUpdated===true)
        {
          d.lastTimeData = Date.now();
          d.lastTimeDataProperty[propertyName] = Date.now();
          if(isSelectedDeviceUpdated===true)
            this.setState({selectedDevice:d});

          break;
          //console.log("updateDeviceParam", deviceId,propertyName,value);    
        }
      }
    }

    this.setState({devices:oldDevices});   
  }

  updatePropertyOn(o, propertyName, value)
  {
    if(o!=null)
    {
       if(o.hasOwnProperty(propertyName)) 
       {
        o[propertyName] = value;
        //o["LastPropertyUpdateTime"][propertyName] = Date.now();
        //console.log("updatePropertyOn", o, propertyName);
        return true;
       }
    }
    return false;
  }

  selectDevice(device) {
    /*if(this.state.selectedDevice!=null)
    {
      this.state.comm.removeFromGroup(this, this.state.selectedDevice.deviceId);
    }*/
    console.log("selectDevice", device);
    this.setState({selectedDevice:device});
    this.state.comm.getFullDeviceData(this, device.deviceId);
    // this.state.comm.addToGroup(this, device.deviceId);
  }

  setGraphData(symbol,title)
  {
    this.setState({graphSymbol:symbol});
    this.setState({graphTitle:title});
    //console.log(symbol, title);
  }

  refreshAlarms()
  {
    if(this.state.comm!=null)
       this.state.comm.GetAlarmsFor(this,this.state.accessToken,"xoxo");
  }
  yourAlarms(alarms)
  {
    this.setState({alarms:alarms});
  }
  deviceAlarms(alarms)
  {
    this.setState({deviceAlarms:alarms});
  }
  selectAlarm(alarm) {
    console.log("selectAlarm", alarm.alarmId);
    this.setState({selectedAlarm:alarm});
  }


  render()
  {
    if (this.state.redirectToLogin===true) {
      this.setState({redirectToLogin:false});
      return <Router> <Redirect push to="/login" /> </Router>;
    }
    if (this.state.redirectToProfile===true) {
      this.setState({redirectToProfile:false});
      return <Router> <Redirect push to="/profile" /> </Router>;
    }
    if (this.state.redirectToHome===true) {
      this.setState({redirectToHome:false});
      return <Router> <Redirect push to="/" /> </Router>;
    }

    let login = this.state.accessToken===null ?  <UserLogin app={this} accessToken={this.state.accessToken} /> : null;



    return (
      <div className="App">
        <Router>
          <BarTop accessToken={this.state.accessToken}/>

          <div className="content">
            <Switch>
                <Route path="/" exact render={(props) => (<ViewInitial at={this.state.accessToken}  {...props}/>) }/>
                <Route path="/login" exact render={(props) => (<UserLogin accessToken={this.state.accessToken}  {...props}/>) }/>
                <Route path="/profile" render={(props) => (<Profile accessToken={this.state.accessToken} usersSummary={this.state.usersSummary} {...props}/>) }/>

                <Route path="/device/:id" render={(props) => ( <DeviceView selectedDevice={this.state.selectedDevice} {...props}/> )} />

                <Route path="/devicegraph/:id/:propName" render={(props) => ( <DeviceViewPropertyGraph  {...props} 
                                                                                  symbol={this.state.graphSymbol}
                                                                                  title={this.state.graphTitle}
                                                                                  d={this.state.selectedDevice}
                                                                                /> 
                                                                                )} />

                <Route path="/devicemultigraph/:id/:propName" render={(props) => ( <DeviceViewPropertyGraph  {...props} 
                                                                                     symbol={this.state.graphSymbol}
                                                                                     title={this.state.graphTitle}
                                                                                     d={this.state.selectedDevice}
                                                                                   /> 
                                                                                )} />                                                                                

                <Route path="/NetworkStatus" render={(props) => (<>
                                                                    <NetworkStatus devices={this.state.devices} {...props}/>
                                                                    <DeviceViewNetStat selectedDevice={this.state.selectedDevice} {...props}/> 
                                                                 </>)} />

                <Route path="/Alarms" render={(props) => ( <AlarmHistory alarms={this.state.alarms} {...props}/> )} />
                <Route path="/MyIMotions" render={(props) => (<> <div>MyIMotions</div> </>)} />
                <Route path="/Support" render={(props) => (<> <div><Support /></div> </>)} />

                <Route path="/PrivacyPolicy" render={(props) => (<> <div><PrivacyPolicy /></div> </>)} />
                <Route path="/TermsAndConditions" render={(props) => (<> <div><TermsAndConditions /></div> </>)} />
                <Route path="/PartnerAccess" render={(props) => (<> <div><PartnerAccess /></div> </>)} />

                <Route path="/CookiesPolicy" render={(props) => (<> <div><CookiesPolicy /></div> </>)} />

                <Route path="/art" render={(props) => 
                  (<Art {...props} /> )} /> 

                <Route component={Notfound} />
            </Switch>
          </div>

          <BarBottom />
        </Router>
        <ToastContainer />
      </div>
    );
  }
}

export default App;

/*
<header className="App-header">
<img src={logo} className="App-logo" alt="logo" />
<p>
  Edit <code>src/App.js</code> and save to reload.
</p>
<a
  className="App-link"
  href="https://reactjs.org"
  target="_blank"
  rel="noopener noreferrer"
>
  Learn React
</a>
</header>*/