import React from 'react';
import { Link } from "react-router-dom";

class AlarmHistory extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    componentDidMount()
    {
        window.app.refreshAlarms();   
    }

    render () {
      var alarms = this.props.alarms;
      if(alarms == null || alarms.length===0) { return <div><h1>No alarms</h1> </div> } 

      return <>
               <h1>Alarms Of IMotion Network</h1>
               <table>
                   <thead>
                      <tr>
                          {/* <th className="tableHeader" >ID</th>
                          <th className="tableHeader" >Status</th> */}
                          <th className="tableHeader" >Name</th>
                          <th className="tableHeader" >Type</th>
                          <th className="tableHeader textLeft" >Reason</th>
                          <th className="tableHeader rightLeft" >Value</th>
                          <th className="tableHeader textLeft" >Op</th>
                          {/* <th className="tableHeader" >Description</th> */}
                          <th className="tableHeader textLeft" >Ref</th>
                          <th className="tableHeader" >Time</th>
                          {/* <th className="tableHeader" >SMSs</th>
                          <th className="tableHeader" >Created</th> */}
                      </tr>
                   </thead>
                   <tbody>
                      {this.props.alarms.map((d,i)=>(
                          <tr className="deviceRow" key={"devvss_" + i} value={i} onClick={(e)=>window.app.selectAlarm(d)}>
                              {/* <td>{i}</td>
                              <td>{d.online===0 ? <span className="red">⬤</span> : <span className="dark">⬤</span>}</td> */}
                              <td>{d.name}</td>
                              <td>{d.type}</td>
                              <td className="textLeft">{d.reason}</td>
                              <td className="rightLeft">{d.value}</td>
                              <td className="textLeft">{d.operand}</td>
                              <td className="textLeft">{d.compareTo}</td>
                              {/* <td>{d.description}</td> */}
                              <td className="textRight">{d.created.replace(/-/g,"/").replace(/[TZ]/g," ")} </td>
                              {/* <td>{d.emailsSentTo}</td>
                              <td>{d.smSsSentTo}</td> 
                              <td>{d.created}</td>*/} 
                          </tr>
                      ))}
                   </tbody>
               </table>

          </>
    }
}
export default AlarmHistory;