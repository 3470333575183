import React from 'react';
//import { Link } from "react-router-dom";
import DeviceView from "./DeviceView.js"; 

class DevicesList extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    render () {
      var devices = this.props.devices;
      if(devices===null || devices.length===0) { return <strong>No devices</strong> } 
      
      var isArray = Array.isArray(devices);

      return <>
                  <strong>Devices: </strong>{devices.length}
                  {isArray ? devices.map((d)=>(
                    <div key={d.id}>
                       <DeviceView device={d}/>   
                    </div>
                  ))  : null }

             </>
  }
}
export default DevicesList;