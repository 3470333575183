import * as SignalR from '@microsoft/signalr';

const iotsiurl = "https://iotsihelpi.azurewebsites.net/"; 

const AlarmLogicalValue =
    [
        "NotEqual",
        "Equal",
        "Less",
        "More"
    ]
const AlarmResult =
    [
        "OK",
        "Warning",
        "Error",
        "Critical",
    ]

class ComLib  {
    constructor(app, token)
    {
        this.loginToken = token;
        console.log(token)
        this.transport = 0;
        this.connection = new SignalR.HubConnectionBuilder().
                              withUrl(iotsiurl + "dataHub", 
                              { accessTokenFactory: () => { return this.loginToken }, 
                                transport: this.transport
                              })
                              //.configureLogging(SignalR.LogLevel.Debug)
                              .build();
        this.app = app;
        this.cid = "";
        this.debug = true;
    }
    async startCommunication()
    {
        this.connection.start({ withCredentials: false })
        .then(res => {
                console.log("WS Connected");
                window.app.updateConnectionState(this.connection.state);
            })
            .catch(err => { 
               this.app.setError("No connection to server " + err);
        });
    }
    async setup() 
    {
        this.startCommunication();

        this.connection.serverOrderedDisconnect = function (value) {
          this.connection.hub.stop();
          console.log("Server ordered disconnect");

          window.app.displayToast("Server ordered disconnect");
        };
    
        this.connection.onclose(async () => {
          console.log("Connection closed");
          //this.app.logout();
          await this.startCommunication();

          window.app.displayToast("Connection closed");
          window.app.signOut();
        });

        this.connection.on("ReceiveMessage", (user, message)  => { 
            if(this.debug===true)  console.log("CR ReceiveMessage", user, message); 
        });
        this.connection.on("Anonymous", (user, message)  => { 
            if(this.debug===true) 
               console.log("CR Anonymous", user, message); 
        });
        



        this.connection.on("Toast", (text) => { 
            //this.app.displayInfo(text.toString()); 
            if(this.debug===true) 
               console.log("CR Toast", text);   
        });  

        this.connection.on("UsersSummary", usersSummary => {
            if(this.debug===true)      
               console.log("CR UsersSummary", usersSummary);   

            window.app.setUsersSummary(usersSummary.users);
        });

        this.connection.on("DeviceStatusUpdate", (deviceId, isOnline)  => { 
            if(this.debug===true) 
               console.log("CR DeviceStatusUpdate", deviceId, isOnline); 
        });

        this.connection.on("DeviceUpdateParam", (deviceId, propertyName, value) => {
            if(this.debug===true) 
                console.log("CR DeviceUpdateParam", deviceId, propertyName, value);    

            window.app.updateDeviceParam(deviceId, propertyName, value);   
        });
        
        this.connection.on("DeviceUpdated", (device) => {
            if(this.debug===true) 
               console.log("CR DeviceUpdated", device.deviceId);   

            window.app.updateDevice(device, true);   
        });

        this.connection.on("FullDeviceData", (device) => {
            if(this.debug===true) 
               console.log("CR FullDeviceData", device.deviceId);   

            window.app.updateDevice(device, false);   
        });

        this.connection.on("DeviceAlarm", (device, propCheck) => {
            if(this.debug===true) 
               console.log("CR DeviceAlarm", device, propCheck);   

            //window.app.updateDevice(device, false);   
            window.app.displayToast(AlarmResult[propCheck.logicalResult] + " Alarm " + propCheck.propertyName + " " + AlarmLogicalValue[propCheck.logicalOp] + " " + device.name);
            window.app.refreshAlarms();   
        });

        
        this.connection.on("YourAlarms", (alarms) => {
            if(this.debug===true) 
               console.log("CR YourAlarms", alarms);   

            window.app.yourAlarms(alarms);   
        });
        this.connection.on("DeviceAlarms", (alarms) => {
            if(this.debug===true) 
               console.log("CR DeviceAlarms", alarms);   

               window.app.deviceAlarms(alarms);   
        });
    }

    getUsersSummary(register) {
        this.registerContext = register;
        this.connection.invoke("getUsersSummary", this.loginToken).catch(function (err) {
            return console.error(err.toString());
        });
    }

    // -> yourUsers
    getYourUsers(register) {
        this.registerContext = register;
        this.connection.invoke("GetYourUsers", this.loginToken).catch(function (err) {
            return console.error(err.toString());
        });
    }

    // UserDevices(devices).
    getDevicesForUser(register, userToken, userId) {
        this.registerContext = register;
        this.connection.invoke("GetDevicesForUser", this.loginToken, userId).catch(function (err) {
            return console.error(err.toString());
        });
    }
    // -> UserDevices(devices).
    getYourDevices(register, userToken, userId) {
        this.registerContext = register;
        this.connection.invoke("GetYourDevices", this.loginToken, userId).catch(function (err) {
            return console.error(err.toString());
        });
    }

    // -> YourAlarms
    GetAlarmsFor(register, userToken, deviceId) {
        this.registerContext = register;
        this.connection.invoke("GetYourAlarmsFor", this.loginToken, deviceId).catch(function (err) {
            return console.error(err.toString());
        });
    }

    // -> DeviceAlarms
    GetAlarmsForDevice(register, userToken, deviceId) {
        this.registerContext = register;
        this.connection.invoke("GetYourAlarmsForDevice", this.loginToken, deviceId).catch(function (err) {
            return console.error(err.toString());
        });
    }

    /* GROUPS */
    addToGroup(register, groupName) {
        this.registerContext = register;
        console.log("comlib Adding to group", groupName); 
        this.connection.invoke("AddToGroup", groupName).catch(function (err) {
            return console.error(err.toString());
        });
    }
    removeFromGroup(register, groupName) {
        this.registerContext = register;
        console.log("comlib Removing to group", groupName); 
        this.connection.invoke("RemoveFromGroup", groupName).catch(function (err) {
            return console.error(err.toString());
        });
    }

    /* Ask Server to get DeviceStatus for device of groupName */
    sendDeviceStatus(register, groupName) {
        this.registerContext = register;
        this.connection.invoke("SendDeviceStatus", groupName).catch(function (err) {
            return console.error(err.toString());
        });
    }

    getFullDeviceData(register, groupName) {
        this.registerContext = register;
        this.connection.invoke("GetFullDeviceData", groupName).catch(function (err) {
            return console.error(err.toString());
        });
    }
}


export default ComLib;