import React from 'react';
import { Link, withRouter } from "react-router-dom";
import * as utils from './utils.js'; 

const beagleSensorKeyNameValue = {
    "id" : { name: "id", symbol: null},
    "linkBBDataId" : { name: "linkBBDataId", symbol:null},
    "windSpeed" : { name: "Wind Speed", symbol:"m/s"},
    "windThrust" : { name: "Wind Thrust", symbol:"m/s"},
    "weatherTemperature" : { name: "Enclosure Temperature", symbol:"°C"},
    "weatherPressure" : { name: "Weather Pressure", symbol:"Pa"},
    "weatherHumidity" : { name: "Enclosure Humidity", symbol:"%"},
    "ozonePPM" : { name: "Ozone Concentration", symbol:"PPM"},
    "ozoneTemperature" : { name: "Ozone Temperature", symbol:"°C"},
    "nO2Temperature" : { name: "NO₂ Temperature", symbol:"°C"},
    "nO2Humidity" : { name: "NO₂ Humidity", symbol:"%"},
    "nO2PPM" : { name: "NO₂ Concentration", symbol:"PPM"}, //mg/m³
    "sO2Temperature" : { name: "SO₂ Temperature", symbol:"°C"},
    "sO2Humidity" : { name: "SO₂ Humidity", symbol:"%"},
    "sO2PPM" : { name: "SO₂ Concentration", symbol:"PPM"}, //mg/m³
    "cOTemperature" : { name: "CO Temperature", symbol:"°C"},
    "cOHumidity" : { name: "CO Humidity", symbol:"%"},
    "cOPPM" : { name: "CO Concentration", symbol:"PPM"}, //mg/m³
    "ethanolTemperature" : { name: "Ethanol Temperature", symbol:null/*"°C"*/},
    "ethanolHumidity" : { name: "Ethanol Humidity", symbol:null/*"%"*/},
    "ethanolPPM" : { name: "Ethanol", symbol:null/*"PPM"*/},
    "dustParticleSize" : { name: "Dust Particle Average Size", symbol:"µm"},
    "dustMassConcentrationPM10" : { name: "Dust Mass Concentration PM10", symbol:"µg/cm³"},
    "dustMassConcentrationPM25" : { name: "Dust Mass Concentration PM25", symbol:"µg/cm³"},
    "dustMassConcentrationPM40" : { name: "Dust Mass Concentration PM40", symbol:"µg/cm³"},
    "dustMassConcentrationPM100" : { name: "Dust Mass Concentration PM100", symbol:"µg/cm³"},
    "dustNumberConcentrationPM05" : { name: "Dust Number Concentration PM05", symbol:"#g/cm³"},
    "dustNumberConcentrationPM25" : { name: "Dust Number Concentration PM25", symbol:"#g/cm³"},
    "dustNumberConcentrationPM40" : { name: "Dust Number Concentration PM40", symbol:"#g/cm³"},
    "dustNumberConcentrationPM10" : { name: "Dust Number Concentration PM10", symbol:"#g/cm³"},

    "soundAvg" : { name: "Average Sound Power", symbol:"dB"},
    "colorAed" : { name: "AED LED state", symbol:"", valueMap:{"0":"N/A","1":"Green","2":"Red","3":"No response"}},
    "buttonAed" : { name: "AED door state", symbol:"", valueMap:{"0":"Closed","1":"Opened","2":"N/A"}},
    "buttonSos" : { name: "SOS button state", symbol:"", valueMap:{"0":"Idle","1":"Activated","2":"N/A"}},
    "buttonInfo" : { name: "Info button state", symbol:"", valueMap:{"0":"Idle","1":"Activated","2":"N/A"}},
    

    "uvLight" : { name: "UV Light Index", symbol:""},
    "irLight" : { name: "IR Light Index", symbol:null/*""*/},
    "created" : { name: "created", symbol: null},
    "modified" : { name: "modified", symbol: null},
} 


class DeviceViewBeagleSensor extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          selection:[]
      };
    }
    componentDidMount()
    {
       // if(window.app.state.comm!=null)
       //    window.app.state.comm.addToGroup(this, this.props.match.params.id);
    }
    toPropView(propName, symbol, title)
    {
        //console.log( this.props);
        window.app.setGraphData(symbol,title);
        this.props.history.push("/devicegraph/"+this.props.d.deviceId+"/"+propName); //+"/"+encodeURIComponent(symbol)+"/"+encodeURIComponent(name));
    }
    toMultiPropView(propName, symbol, title)
    {
        //console.log( this.props);
        window.app.setGraphData(symbol,"Viewing " + title);
        var ns = "";
        for(var s of this.state.selection)
           ns = ns + "," + s.key;

        ns = ns.substring(1);
        window.app.setGraphData(symbol, ns);

        this.props.history.push("/devicemultigraph/"+this.props.d.deviceId+"/"+ns); //+"/"+encodeURIComponent(symbol)+"/"+encodeURIComponent(name));
    }
    onCheckboxClick(key,select,event,d)
    {
        //console.log(key, select, event, d);
        var newSelection = this.state.selection;

        for (var i = newSelection.length - 1; i >= 0; --i) {
            if (newSelection[i].key === key) {
                newSelection.splice(i,1);
                console.log("removed", key);
                return; // nothing to do
            }
        }
        
        newSelection.push({key:key, symbol:d.symbol, name:d.name});
        this.setState({selection:newSelection});
    }

    render () {
        var d = this.props.data; 
        if(d===null || d===undefined) return null; 
        var device = this.props.d; 
        var t = 0;
        var red=[235,0,0];
        var green=[0,255,0];
        var newColor = "rgba(255,0,0,1)";

        //console.log("DeviceViewBagleSensor", device);

        var renderbase = Object.entries(d).map(([key, value]) => {
            // Pretty straightforward - use key for the key and value for the value.
            // Just to clarify: unlike object destructuring, the parameter names don't matter here.
            var d = beagleSensorKeyNameValue[key];
            if(d!==undefined && d.symbol!=null)
            {
                if (device.lastTimeDataProperty===undefined || device.lastTimeDataProperty[key]===undefined) 
                {
                    t=0;
                }
                else 
                {
                    t = 1-utils.msToTime(device.lastTimeData-device.lastTimeDataProperty[key])/240.0; // decay 4 minutes
                }
                var c = utils.interpolateColor(red,green,t);
                newColor = "rgba("+c[0]+","+c[1]+","+c[2]+")";

                // return <>
                //            <div className="infoSide textRight"> {d.name} </div>
                //            <div className="sensorSide"> {value} {d.symbol} </div>
                //         </>
                return <tr key={"dvbs_"+key} >
                             
                             <td><input type="checkbox" onClick={(event)=>this.onCheckboxClick(key,value,event,d)}/></td>
                             <td  className="textLeft">{d.name}</td>
                             
                             <td/>
                             {d.valueMap===undefined ?
                             <td  className="textLeft">{value} {d.symbol}</td>
                             :
                             <td  className="textLeft">{d.valueMap[value]} <small><small>({value})</small></small></td>
                             }
                             {/* <td>{d.isAlarm===0 ? <span className="red">⬤</span> : <span className="dark">⬤</span>}</td>  */}
                             <td><span style={{color:newColor}}>⬤</span></td> 
                             
                             {this.state.selection.length===0 ?
                             <td onClick={(event)=>this.toPropView(key,d.symbol,d.name)}>📈</td>
                             :
                             <td onClick={(event)=>this.toMultiPropView(key,d.symbol, d.name)}>📊</td>
                            }

                             {/* {d.LastPropertyUpdateTime} */}

                             {/* <td>{t}</td> */}
                             {/* o[propertyName+"Time"] = Date.now(); */}
                     </tr>
            }
        })

        return <table>
                    <thead>
                        <tr>
                            <th width="10px">&nbsp; </th>
                            <th className="textLeft width60">SENSOR</th>
                            <th>&nbsp; </th>
                            <th className="textLeft width30">DATA </th>
                            <th className="width5">&nbsp; </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderbase}
                    </tbody>

                </table>
    }
}
export default withRouter(DeviceViewBeagleSensor);