import React from 'react';
import { Link } from "react-router-dom";

class BarBottom extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    render () {
        return <div className="footer-main">
                    <div className="grid-item">            
                        <div className="footer-logo">
                            <img src="/img/Logotip_INFINITUS.png" width="80%"/> 
                        </div>
                    </div>
                        
                    <div className="grid-item">
                            INFINITUS d.o.o.<br/>
                            Prevale 7, 1236 Trzin, Slovenia, Europe<br/>
                            Phone: +386 1 5128052<br/>
                            E-mail: <Link style={{color:"white"}} to="mailto:info@infinitus-outdoor.com" title="info@infinitus-outdoor.com" name="info@infinitus-outdoor.com">info@infinitus-outdoor.com</Link>
                    </div>

                        
                    <div className="grid-item" style={{paddingTop:"30px"}}>
                        <div style={{paddingTop:"2px"}}>
                            <Link style={{color:"white"}} to="./PrivacyPolicy" title="Privacy Policy" name="Privacy Policy">Privacy Policy</Link><br/>
                            <Link style={{color:"white"}} to="./TermsAndConditions" title="Terms and Conditions" name="Terms and Conditions">Terms and Conditions</Link><br/>

                            <Link style={{color:"white"}} to="./CookiesPolicy" title="Cookies Policy" name="Cookies">Cookies Policy</Link> <br/>
                            {/* <Link style={{color:"white"}} to="./PartnerAccess" title="Partner Access" name="Partner Access">Partner Access</Link>  <br/> */}

                            {/* <Link style={{color:"white"}} target="_blank" rel="noopener noreferrer" to={{pathname:"https://controlmotion-iot.com/partners/Support/SupportPage.php"}} title="Support" name="Support">Support</Link>  <br/> */}

                            

                            <Link style={{color:"white"}} to="./PartnerAccess" title="Support" name="Support">Support</Link>                         </div>
                    </div>
            </div>
    }
}
export default BarBottom;