import React from 'react';
//import { Link } from "react-router-dom";
//import { Router, Redirect, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import DevicesListShort from "./DevicesListShort.js";

class UserProfile extends React.Component {
    constructor(props){
      super(props);
      this.state = {
           summary: null
      };
    }


    render () {
        var up = this.props.userProfile;
        if(up===null) { return <div><h3>Invalid profile</h3></div> } 
        
        return <>
                    <h1>User</h1>
                    <div>
                        <strong>Name:</strong> {up.name} <br/>
                        <strong>Group:</strong> {up.group} <br/>
                        <strong>Address:</strong> {up.address}, {up.country}<br/>
                        <strong>Web:</strong> <a rel="noopener noreferrer" target="_blank" className="dark" 
                                                 href={"https://"+up.website}>{up.website}</a> <br/>
                        <strong>Contact:</strong> {up.contact} <br/>
                        <strong>Sensors:</strong> {up.sensorCount} <br/>
                        <strong>Alarms:</strong> {up.alarmCount} <br/>
                        
                    </div>
                    <DevicesListShort devices={up.devices}/>
               </>
    }
}
class Profile extends React.Component {
    constructor(props){
      super(props);
      this.state = {
           summary: null
      };
    }

    componentDidMount()
    {
        if(window.app.state.comm!=null)
        {
           window.app.state.comm.getUsersSummary(window.app,window.app.state.accessToken);
        }
    }
    async logout()
    {
        console.log("loging out")
        await window.app.signOut();
    }


    render () {
        var at = this.props.accessToken;
        //console.log(this.props.usersSummary)
        if(at===null) { return <div><h3>Invalid profile</h3> <Redirect push to="/login" /> </div> } 
        
        var isArray = Array.isArray(this.props.usersSummary);

        return <>
                    <h1>Profile</h1>
                    <div>
                        <strong>Name:</strong> {at.name} <br/>
                        <strong>User:</strong> {at.userName} <br/>
                        <strong>Roles:</strong> {at.roles.map((r)=>(<span key={r}>{r} &nbsp;</span>))} <br/>
                    </div>

                    <br/>
                   
                    {/* <button to="#" onClick={()=>window.app.setState({accessToken:null})}>Logout</button> */}
                    <button to="#" onClick={()=>this.logout()}>Logout</button>

                    {isArray ? this.props.usersSummary.map((u)=>(
                         <UserProfile key={u.userId} userProfile={u}/>   
                    ))  : <></>}

               </>
    }
}

export default Profile;