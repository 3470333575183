import React from 'react';
import { Redirect, Link, withRouter } from "react-router-dom";

class Art extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          enabled: false,
          nodata: false,
          data: null,
          uuid: 0
      };
      this.getData = this.getData.bind(this);
    }
    componentDidMount()
    {
        this.intervalHandle = setInterval(this.getData, 100); // every n s
    }
    componentWillUnmount() { 
        clearInterval(this.intervalHandle); 
    }

    async getData() {
        //this.forceUpdate();
        this.setState({uuid:this.state.uuid+1});
    }

    lerp(v0, v1, t) {
        return (1 - t) * v0 + t * v1;
    }

    render () {
        // art part 
        var centerX=40, centerY=40;
        var uid = this.state.uuid+1;
        var duid = Math.floor(uid % 16);
        var sizeX = 8;
        var sizeY = 8;
        var viewSize = 400;
        var rectSize = viewSize/10; // 40
        var rectHSize = -(rectSize/2); // -20
        var gutter = (rectSize / sizeX); // 5

        var speed = 50;
        var time = Date.now() / speed; 
        const millis = time % 360;
        const coloris = millis/10;//  36;
        const centerShift = (coloris/10) % sizeX;
        
        const degs = uid % sizeX; // const skew for uid
        var   firstAngle=millis, secondAngle=degs, thirdAngle=0; 
        var   name="Name", description="Description", link="Link"; 
        var rect;
        var rects = [];
        var sin = Math.abs(Math.sin(coloris)) * 255;
        var r=sin;
        var g=sin;
        var b=sin;
        var a=centerShift/7; // 0..1

        if(coloris%97>42)
            r = sin*(centerShift*uid)/155;
        if(coloris%199<101)
            g = sin*(centerShift*uid)/395;
        if(coloris%257>149)
            b = Math.abs(sin*(a));

        
        for(var x=1;x<=sizeX;x++)
        {
            var preSymetry = 0;//(uid+x*y); 
            for(var y=1;y<=sizeY;y++)
            {
               var symetry = 1;//(uid+x*y); 
               var idUniqe = (uid*(x*y)); 
               if(x>=centerShift)  //
                  symetry = -symetry;  
               if(y>=centerShift)
                  symetry = -symetry;  

               //g = (g*(idUniqe-centerShift));

               var lSymetry = this.lerp(preSymetry, symetry, millis/64); //(x*y)/64);
               
               //a = -symUniqe / 255;   
               //rect = <g transform ={"translate("+ (x * centerX + rectSize*(x-1)) + "," + (y * centerY + rectSize*(y-1)) +") rotate("+ firstAngle+","+ secondAngle +"," + thirdAngle+ ")"}>
               // <g transform ={"translate("+ (x * (rectSize+gutter)) + "," + (y * (rectSize+gutter)) +") rotate("+ firstAngle+","+ secondAngle +"," + thirdAngle+ ")"}>
               rect = <g transform ={"translate("+ (x * (rectSize+gutter)) + "," + (y * (rectSize+gutter)) +")"} class="blendif">
                        <rect x={rectHSize} y={rectHSize} width={rectSize} height={rectSize} 
                              fill = {"rgba("+r+","+g+","+b+","+ a +")"} 
                              fill = {"orange"} 
                              transform ={"rotate("+ (lSymetry*firstAngle) + ",0,0) skewY("+ ((symetry*idUniqe) % 89) + ")"}/>
                        {/* fill="rgba(124,240,10,0.5)" fill="white"*/}
                        {/* <text x="-10"class="small">{centerShift.toFixed(2)} #{idUniqe}</text>
                        <text x="-20" y="10"class="small">{preSymetry.toFixed(1)}</text>
                        <text x="-5" y="10"class="small">{symetry.toFixed(0)}</text>
                        <text x="5" y="10"class="small">{lSymetry.toFixed(1)}</text>

                        <text x="-25" y="-20"class="small">{r.toFixed(1)}</text>
                        <text x="-25" y="-15"class="small">{g.toFixed(1)}</text>
                        <text x="-25" y="-10"class="small">{b.toFixed(1)}</text>  */}
                        {/* <polygon points="100,10 40,198 190,78 10,78 160,198"/> */}
                        <g class="blendor"  
                                fill={"rgba("+r+","+g+","+b+","+ a +")"}
                                transform={"scale(0.2) rotate(-135) translate(-40,0)"
                                }>
                        <path d="M0 100 v-100 h100  
                                 a-50,-50 90 0,1 0,100 
                                 a-50,-50 90 0,1 -100,0 z" />
                        </g>
                        {/* <use xlink:href="#heart" class="outline" fill="red" /> */}
                      </g>     
                if((uid+idUniqe)%128<64)
                   rects.push(rect);
            }
        }
        var heartScale=centerShift/100;

        var heart = <path d={"M0 0 v0 h200 a10"+duid%5+",10"+duid%4+" 90 0,1 0,200 a10"+duid%4+",10"+duid%5+" 20"+duid%4+" 0,1 -20"+duid%4+",0 z"} />
        
        return <>
          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350">
              <defs><style type="text/css">@import url("https://fonts.googleapis.com/css?family=Indie+Flower|New+Rocker");</style></defs>
              <style>
               .bl {"{mix-blend-mode:color-dodge;stroke-dasharray:6;stroke:#ffffff05;stroke-width:200;}"}
              .main {"{fill:#aaaaaa;direction:ltr;font-size:55px;font-family:New Rocker}"}
              .undr {"{fill:#999999;direction:ltr;font-size:15px;font-family:New Rocker}"}
              .old  {"{fill:#999999;mix-blend-mode:overlay;direction:ltr;font-size:55px;font-family:New Rocker}"}
              .sig  {"{fill:white;direction:ltr;font-size:25px;font-family:Indie Flower}"}</style>
              <rect width="100%" height="100%" fill="black"/>
              <text x="50%" y="40%" dominant-baseline="middle" text-anchor="middle" class="main">Sell your soul</text>
              <text x="20" y="210" dominant-baseline="middle" text-anchor="left" class="undr">Sign here: ...................................................................</text>
              <text x="10%" y="17%" dominant-baseline="middle" text-anchor="left" class="old" transform="scale(0.19 3)"></text>
              <text x="90" y="205" dominant-baseline="middle" text-anchor="right" class="sig" textLength="246" lengthAdjust="spacingAndGlyphs"></text>
              </svg>

          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 350 350">
              <defs>
                  <style type="text/css">@import url("https://fonts.googleapis.com/css?family=Indie+Flower|Gamja+Flower|New+Rocker");
                  </style>
               </defs>

          <style>.main {"{fill:#aaaaaa;direction:ltr;font-size:55px;font-family:New Rocker}"} 
                 .undr {"{fill:#999999;direction:ltr;font-size:15px;font-family:New Rocker}"} 
                 .old  {"{fill:#999999;mix-blend-mode:overlay;direction:ltr;font-size:55px;font-family:New Rocker}"} 
                 .sig  {"{fill:white;direction:ltr;font-size:25px;font-family:Indie Flower}"} 
          </style>
            <rect width="100%" height="100%" fill="black"/>
            <text x="50%" y="40%" dominant-baseline="middle" text-anchor="middle" class="main">
                Sell your soul
            </text>

            <text x="20" y="210" dominant-baseline="middle" text-anchor="left" class="undr">
                Sign here: ...................................................................
            </text>

            <text x="10%" y="17%" dominant-baseline="middle" text-anchor="left" class="old" transform="scale(0.19 3)">
                3ed54831f488a22b28398de0c567a3b064b937f54f81739ae9bd545967f3abab
            </text>

            <text x="90" y="205" dominant-baseline="middle" text-anchor="right" class="sig" textLength="246" lengthAdjust="spacingAndGlyphs">
                Rock'n'Roll
            </text>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox={"0 0 "+ viewSize + " " +viewSize}>
              <defs>
                       <style>
                         .base { "{fill: black; font-family: sans-serif; font-size: 12px; }" }
                         .small { "{fill: white; font-family: sans-serif; font-size: 5px; }" }
                         .outline { "{stroke:none; stroke-width:0; fill-rule: nonzero; }" }
                         .blended { "{mix-blend-mode: overlay }" }
                         .blendif { "{mix-blend-mode: saturation }" }
                         .blendor { "{mix-blend-mode: luminosity }" }
                       </style>
              </defs>
                <rect width="100%" height="100%" fill="orange" />
                <text x="25" y="17" class="base">{name} {uid} {duid} {centerShift} {coloris.toFixed(3)} {millis.toFixed(3)}</text>
            {/* <g transform="rotate(90) translate(25,-10)">
                <text x="0" y="0" class="base" >{description}</text>
            </g> */}
            {/* <text x="20" y="120" class="base" >{duid%10}</text> */}
            <g class="outline"  
               fill={"red"}
               fill = {"rgba("+(r+59)+","+a+","+a+",1)"} 
               transform={"scale(1)  rotate(-135) translate(-400,-110)"
                }>
                    {/* <path d={"M0 0 v0 h200 a100,100 90 0,1 0,200 a100,100 90 0,1 -200,0 z"} /> */}
                    {/* <path d={"M0 0 v0 h200 a10"+duid%4+",10"+duid%3+" 90 0,1 0,200 a10"+duid%3+",10"+duid%4+" 20"+duid%4+" 0,1 -20"+duid%2+",0 z"} /> */}
                    {/* <path d={"M0 0 v0 h200 a100,100 90 0,1 0,200 a100,100 90 0,1 -200,0 z"} /> */}
                    {heart}

                <g class="blended"  
                   fill = {"rgba("+r+","+g+","+b+","+ a +")"} 
                   transform={"scale(1.0"+ duid + ") translate(-"+duid+",-"+duid+")"
                    }>
                        {heart}
                </g>            
                <g class="blended"  
                   fill = {"rgba("+r*3+","+g+","+b+",0.3)"} 
                   transform={"scale(1.0"+ degs + ") translate(-"+degs+",-"+degs+")"
                    }>
                        {heart}
                </g> 
            </g>            

            <a href={link}><text x="25" y="395" class="base">{link}</text></a>
            {/* <g transform ={"translate("+centerX+ "," + centerY+") rotate("+ firstAngle+","+ secondAngle +"," + thirdAngle+ ")"}>
                <rect x={-rectSize/2} y={-rectSize/2} width={rectSize} height={rectSize} fill="white" />
            </g> */}
            {rects.map((r)=>(r))}
         </svg>


          {/* <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox="0 0 400 400">
              <style>.b { fill: red; font-family: sans-serif; font-size: 12px; } .bo {mix-blend-mode:overlay} .bs {mix-blend-mode:saturation} .bl {mix-blend-mode:luminosity} </style>
              <rect width="100%" height="100%" fill="orange"/>
              <g class="b" transform="scale(1) rotate(-130) translate(-400,-110)"><path d="M0 0 v0 h200 a101,100 90 0,1 0,200 a100,101 201 0,1 -200,0 z"/>
              <g class="bo" fill="rgba(171,47,47,0.84)" transform="scale(1.07) translate(-3,-3)"><path d="M0 0 v0 h200 a101,100 90 0,1 0,200 a100,101 201 0,1 -200,0 z"/></g>
              <g class="bo" fill="rgba(171,47,47,0.84)" transform="scale(1.06) translate(-2,-2)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
              </g>
              <g transform="scale(0.1)">
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(000,000) rotate(0)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(000,1000) rotate(0)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(000,2000) rotate(0)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(000,3000) rotate(0)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(1000,000) rotate(0)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(1000,1000) rotate(177)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(1000,2000) rotate(354)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(1000,3000) rotate(171)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(2000,000) rotate(0)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(2000,1000) rotate(354)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(2000,2000) rotate(348)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(2000,3000) rotate(342)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(3000,000) rotate(0)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(3000,1000) rotate(171)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(3000,2000) rotate(342)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
                  <g fill="rgba(171,47,47,0.84)" class="bs" transform="translate(3000,3000) rotate(153)"><path d="M0 0 v0 h200 a107,101 90 0,1 0,200 a107,100 207 0,1 -201,0 z"/></g>
              </g></svg> */}

       </>
    }
}
export default withRouter(Art);