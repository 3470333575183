import React from 'react';
import { Link } from "react-router-dom";

class ViewDeviceShort extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }

    render () {
        
        return <span>  
                    <Link to={"/device/"+ this.props.device.deviceId} className="dark">{this.props.device.name}</Link>
                    {/* //  <Route path="/device/:id" render={(props) => ( <DeviceView  {...props}/> )} /> */}
               </span>
    }
}
export default ViewDeviceShort;