import React from 'react';
import { Link } from "react-router-dom";
import DeviceViewBeagleSensor from './DeviceViewBeagleSensor.js';
import DeviceViewIMotionFlow from './DeviceViewIMotionFlow.js';
import DeviceViewIMotionG6 from './DeviceViewIMotionG6.js';
import DeviceViewDisplayControl from './DeviceViewDisplayControl.js';
import DisplayGMap from './DisplayGMap.js';

class DeviceViewBasicInfo extends React.Component {
    constructor(props){
      super(props);
      this.state = {
         currentView:"Diagnostic"
      };
    }
    componentDidMount()
    {
       // if(window.app.state.comm!=null)
       //    window.app.state.comm.addToGroup(this, this.props.match.params.id);
    }
    chooseView(v)
    {
        this.setState({currentView:v});
    }
    render () {
        var d = this.props.d; 
        if(d===null) return null; 

        return  <>
                    <div className="infoSide textRight">
                        <span className="textRight bold">Serial       </span>  <br/>
                        <span className="textRight bold">Model        </span>  <br/>
                        <span className="textRight bold">Brand        </span>  <br/>
                        <span className="textRight bold">Name         </span>  <br/>
                        <span className="textRight bold">Description  </span>  <br/>
                        <span className="textRight bold">Address      </span>  <br/>
                        <span className="textRight bold">Location     </span>  <br/>
                    </div>
                    <div className="rightSide">
                        <span className="textLeft">{d.serial}         </span>  <br/>
                        <span className="textLeft">{d.model}          </span>  <br/>  
                        <span className="textLeft">{d.brand}          </span>  <br/> 
                        <span className="textLeft">{d.name}           </span>  <br/> 
                        <span className="textLeft">{d.description}    </span>  <br/> 
                        <span className="textLeft">{d.compactAddress} </span>  <br/>
                        <span className="textLeft">{d.coordinate.latitude},{d.coordinate.longitude} </span>  <br/><br/>
                    </div>
                </>
    }
}

class DeviceViewNetStat extends React.Component {
    constructor(props){
      super(props);
      this.state = {
         currentView:"Information"
      };
    }
    componentDidMount()
    {
       // if(window.app.state.comm!=null)
       //    window.app.state.comm.addToGroup(this, this.props.match.params.id);
    }
    chooseView(v)
    {
        this.setState({currentView:v});
    }
    render () {
        var d = this.props.selectedDevice; 
        var cv = this.state.currentView;
        if(d===null) return null; 

        return <>
            
            <div className="content-device">
            <h2>{d.name}</h2> 

                <div className="leftSide">
                   <img src={d.imageURL} alt="ImageUrl" width="100%"/>
                </div>
                <div className="rightSide">
                    <div className="mapSide">
                         <DisplayGMap center={{lat: d.coordinate.latitude, lng: d.coordinate.longitude}} position={{lat: d.coordinate.latitude, lng: d.coordinate.longitude}} />
                    </div>                    
                    
                    <Link to="#" className={"darker " + (cv==="Information" ? "strong" : "")} onClick={(e)=>this.chooseView("Information")}>
                        <img src="/icons/icon_INFORMATION.svg" className="icon-1"/>     
                        Information</Link> 
                    { d.iMotionFlow != null ?
                    <Link to="#" className={"darker " + (cv==="Diagnostic" ? "strong" : "")} onClick={(e)=>this.chooseView("Diagnostic")}>
                         <img src="/icons/icon_DIAGNOSTICS.svg" className="icon-1"/> 
                        Diagnostic</Link> : null }
                    { d.beagleSensor != null ?
                    <Link to="#" className={"darker " + (cv==="Environment" ? "strong" : "")} onClick={(e)=>this.chooseView("Environment")}>
                         <img src="/icons/icon_ENVIRONMENT.svg" className="icon-1"/> 
                        Environment</Link>: null }
                    { d.iMotionG6 != null ?
                    <Link to="#" className={"darker " + (cv==="State" ? "strong" : "")} onClick={(e)=>this.chooseView("State")}>
                         <img src="/icons/icon_INFORMATION.svg" className="icon-1"/> 
                        State</Link>: null }
                    { d.displayControl != null ?
                    <Link to="#" className={"darker " + (cv==="Settings" ? "strong" : "")} onClick={(e)=>this.chooseView("Settings")}>
                         <img src="/icons/icon_SETTINGS.svg" className="icon-1"/> 
                        Settings</Link> : null }
                     <br/><br/>

                    <div className="sensorSide">
                        {cv==="Information" ? <DeviceViewBasicInfo  d={d}/> : null }
                        <div className="sensor-main">
                                {cv==="Diagnostic" ?  <DeviceViewIMotionFlow data={d.iMotionFlow} d={d}/> : null }
                                {cv==="Environment" ? <DeviceViewBeagleSensor data={d.beagleSensor} d={d}/> : null }
                                {cv==="State" ?       <DeviceViewIMotionG6 data={d.iMotionG6} d={d}/> : null }
                                {cv==="Settings" ?    <DeviceViewDisplayControl data={d.displayControl} d={d}/> : null }
                        </div>
                    </div>

                    

                </div>

           </div>
        </>
    }
}
export default DeviceViewNetStat;