
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link,withRouter } from "react-router-dom";
import ReactDom from 'react-dom';

var md = `
# Cookies Policy
## What are cookies?
Cookies are small text files that inform us about how many times a visitor visits our site and what interests them in these visits. Cookies do not contain any data themselves. You always have the ability to accept or decline cookies. Their storage is under the complete control of the browser used by the user.

Cookies are small files that the visited sites send to your computer and stored on it. Cookies are stored in the web browser’s file directory. On next visit to the site, the browser reads a cookie and provides information to the site or other item that set the cookie.


## Why are cookies needed?
Cookies are essential for providing user-friendly online services. Many e-commerce functions would not be possible without cookies. A quicker and easier interaction between the web user and the website. With their help, the web site remembers the individual’s preferences and experiences, thus saving time, making web browsing more effective and enjoyable.

## Why are websites using cookies?
Cookies are used on websites to maintain fresh and appropriate content that is in accordance with the interests and preferences of a particular web user. The reasons for the use of cookies are more than the first of all their ability to store information about the status of a particular website (details of the customization of a particular website), help with the implementation of various online services (online stores), help to collect various statistics, about the habits of the web user – since it is only possible to monitor the frequency of visiting a particular web site.

By using cookies, businesses can evaluate the effectiveness of their website design, as well as the relevance of the type and number of ads they offer to users on their websites.


## Disabling Cookies
You decide to allow yourself to save cookies on your computer or not. You can control and modify cookie settings using the Web Browser settings. For information on cookie settings, select the web browser you are using.

- Internet explorer
https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d

- Mozilla Firefox
https://support.mozilla.org/en-US/

- Chrome
https://support.google.com/chrome/answer/95647?hl=sl-sl&p=cpn_cookies

- Opera
https://help.opera.com/en/latest/

- Safari
https://support.apple.com/



## Do all cookies work the same way?
No! There are several types of cookies that have different purposes. Cookies vary by function, duration, and client that they install.

If you turn off cookies, some of the webpage options may be disabled. Even when turning off all cookies, the web browser still gets some information. This information is necessary for the basic operation of our website.
`;



class CookiesPolicy extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        selection:[]
      };
    }

    render() {
        return <ReactMarkdown children={md} remarkPlugins={[remarkGfm]} /> 
    }
}
export default withRouter(CookiesPolicy);