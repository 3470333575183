/* FIX Edit on Iotsi */ 

import React from 'react';
import { Link } from "react-router-dom";
import Slider, { Range, SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css'; 
const { Handle } = Slider;
//https://slider-react-component.vercel.app/demo/handle

const displayControlKeyNameValue = {
    "cmDisplayControlId" : { name: "id", symbol: null},
    "ambientLightMax" : { name: "Ambient Light Max", symbol: ""},
    "ambientLightMin" : { name: "Ambient Light Min", symbol:""},
    "ambientSensorEnabled" : { name: "Ambient Sensor Enabled", symbol:""},
    "autoSourceSearch" : { name: "Auto Source Search", symbol:""},
    "averageAmbientLight" : { name: "Average Ambient Light", symbol:""},
    "backlight" : { name: "Backlight", symbol:""},
    "backlightMax" : { name: "Backlight Max", symbol:""},
    "backlightMin" : { name: "Backlight Min", symbol:""},
    "brightness" : { name: "Brightness", symbol:""},
    "contrast" : { name: "Contrast", symbol:""},
    "gamma" : { name: "Gamma", symbol:""},
    "currentAmbientLight" : { name: "Current Ambient Light", symbol:""},
    "displayPosition" : { name: "Display Position", symbol:""},
    "lcdState" : { name: "LCD State", symbol:""},
    "videoSource" : { name: "Video Source", symbol:""},

    "panelControllerBoardFWVersion" : { name: "Panel Controller Board FW Version", symbol:""},
    "panelControllerBoardModel" : { name: "Panel Controller Board Model", symbol:""},
    "panelTimerOffStatus" : { name: "Panel Timer Off Status", symbol:""},

    "blueColorBalance" : { name: "Blue Color Balance", symbol:""},
    "greenColorBalance" : { name: "Green Color Balance", symbol:""},
    "redColorBalance" : { name: "Red Color Balance", symbol:""},

    "linkDisplayControlDataId" : { name: "linkG6DataId", symbol:null},
    "created": { name: "created", symbol: null},
    "modified" : { name: "modified", symbol: null},
} 

class DeviceViewDisplayControl extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    componentDidMount()
    {
       // if(window.app.state.comm!=null)
       //    window.app.state.comm.addToGroup(this, this.props.match.params.id);
    }
    handle(props) {
        const { value, dragging, index, ...restProps } = props;
        return (
          <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value} %`}
            visible={dragging}
            placement="bottom"
            key={index}
          >
            <Handle value={value} {...restProps} />
          </SliderTooltip>
        );
      };

    render () {
        var d = this.props.data; 
        if(d===null || d===undefined) return null; 

        var renderbase = Object.entries(d).map(([key, value]) => {
            // Pretty straightforward - use key for the key and value for the value.
            // Just to clarify: unlike object destructuring, the parameter names don't matter here.
            var d = displayControlKeyNameValue[key];
            if(d!==undefined && d.symbol!=null)
            {
                // return <>
                //            <div className="infoSide textRight"> {d.name} </div>
                //            <div className="sensorSide"> {value} {d.symbol} </div>
                //         </>
                return <tr key={"dvidc_"+key}>
                             <td  className="textLeft">{d.name}</td>
                             <td/>
                             <td  className="textLeft">{value} {d.symbol}</td>
                             <td/>
                             {/* <td>{d.isAlarm===0 ? <span className="red">⬤</span> : <span className="dark">⬤</span>}</td>  */}
                     </tr>
            }
        })

        //var cmarks = { 0:0, [d.contrast]:d.contrast, 100:100}; 
        return <>
                <div className="sliderPacked">
                    Contrast
                    {/* :{d.contrast} */}
                    <Slider min={0} max={100} marks={{0:0, [d.contrast]:d.contrast, 100:100}}
                            defaultValue={d.contrast} handle={this.handle}  />
                </div>                
                <div className="sliderPacked">
                    Gamma
                    {/* :{d.gamma} */}
                    <Slider min={0} max={100} marks={{0:0, [d.gamma]:d.gamma, 100:100}}
                            defaultValue={d.gamma} handle={this.handle}  
                            pushable
                            //handleStyle={[{ backgroundColor: 'yellow' }, { backgroundColor: 'gray' }]}
                            //railStyle={{ backgroundColor: 'blue' }}
                            //trackStyle={[{ backgroundColor: 'blue' }, { backgroundColor: 'green' }]}
                            />
                </div>                

                <p>Color
                    <div className="sliderPacked">
                        Red 
                        <Slider min={0} max={100} marks={{0:0, [d.redColorBalance]:d.redColorBalance, 100:100}}
                                defaultValue={d.redColorBalance} handle={this.handle}  
                                railStyle={{ backgroundColor: 'lightgray' }}
                                trackStyle={[{ backgroundColor: 'red' }, { backgroundColor: 'red' }]}
                                />  
                    </div>
                    <div className="sliderPacked">
                        Green 
                        <Slider min={0} max={100} marks={{0:0, [d.greenColorBalance]:d.greenColorBalance, 100:100}}
                                defaultValue={d.greenColorBalance} handle={this.handle}  
                                railStyle={{ backgroundColor: 'lightgray' }}
                                trackStyle={[{ backgroundColor: 'green' }, { backgroundColor: 'green' }]}
                                /> 
                    </div>

                    <div className="sliderPacked">
                        Blue 
                        <Slider min={0} max={100} marks={{0:0, [d.blueColorBalance]:d.blueColorBalance, 100:100}}
                                defaultValue={d.blueColorBalance} handle={this.handle} 
                                railStyle={{ backgroundColor: 'lightgray' }}
                                trackStyle={[{ backgroundColor: 'blue' }, { backgroundColor: 'blue' }]}
                                /> 
                    </div>              
                </p>
                {/* TODO: Check box {d.ambientSensorEnabled} */}
                <p><input type="checkbox" defaultChecked={d.ambientSensorEnabled} onChange={this.handleChangeChk}/>Enable Ambient Sensor</p>
                <p>Ambient Light
                    
                    <div className="sliderPacked">
                        Current:{d.currentAmbientLight} &nbsp; Average:{d.averageAmbientLight} 
                        <Slider min={d.ambientLightMin} max={d.ambientLightMax} 
                                defaultValue={d.currentAmbientLight}
                                marks={{[d.ambientLightMin]:d.ambientLightMin, 
                                    [d.currentAmbientLight]:d.currentAmbientLight, 
                                    [d.averageAmbientLight]:d.averageAmbientLight, 
                                    [d.ambientLightMax]:d.ambientLightMax}}
                                handle={this.handle}  /> 
                    </div>
                </p>
                <div className="sliderPacked">
                    Backlight 
                    {/* :{d.backlight} */}
                    <Slider min={0} max={100} 
                            defaultValue={d.backlight}
                            marks={{0:d.backlightMin, [d.backlight]:d.backlight, 100:d.backlightMax}}
                            handle={this.handle}  />
                </div>         

                <br/>                    
                <button><strong>Send</strong></button>       
                <br/>

                {/* <Slider min={d.ambientLightMin} max={d.ambientLightMax} defaultValue={d.currentAmbientLight} handle={this.handle} 
                    marks={{0:d.ambientLightMin, 100:d.ambientLightMax}} step={1}/> */}

                <table>

                    <thead>
                        <tr>
                            <th className="textLeft width60">NAME </th>
                            <th>&nbsp; </th>
                            <th className="textLeft width30">DATA </th>
                            <th className="width5">&nbsp; </th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {renderbase}
                    </tbody>
                </table>
            </>
    }
}
export default DeviceViewDisplayControl;