import React from 'react';
//import { Link } from "react-router-dom";
/*
function pad(n, width) {
    var n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}
function displayTime(ticksInSecs) {
    var ticks = ticksInSecs;
    var hh = Math.floor(ticks / 3600);
    var mm = Math.floor((ticks % 3600) / 60);
    var ss = ticks % 60;

    return ( pad(hh, 2) + ":" + pad(mm, 2) + ":" + pad(ss, 2) );
}*/
function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
  
    return hrs + ':' + mins + ':' + secs; // + '.' + ms;
  }

class NetworkStatus extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
      this.getData = this.getData.bind(this);
    }
    componentDidMount()
    {
        this.intervalHandle = setInterval(this.getData, 1000); // every n s
    }
    componentWillUnmount() { 
        clearInterval(this.intervalHandle); 
    }

    async getData() {
        this.forceUpdate();
        //this.setState({uuid:this.state.uuid+1});
    }

    render () {
        if(this.props.devices.length===0) { return <div><h1>No devices</h1> </div> } 

        return <>
                 <h1>Status of imotion Network</h1>
                 <table>
                     <thead>
                        <tr>
                            {/* <th className="tableHeader" >ID</th> */}
                            <th className="tableHeader" >Status</th>
                            <th className="tableHeader" >imotion</th>
                            <th className="tableHeader" >Type</th>
                            <th className="tableHeader" >Serial</th>
                            {/* <th className="tableHeader" >Ext.Temp</th>
                            <th className="tableHeader" >Int.Temp</th> */}
                            <th className="tableHeader" >Address</th>
                            <th className="tableHeader" >City</th>
                            <th className="tableHeader" >Last Data</th>
                            {/* <th className="tableHeader" >Link</th> */}
                        </tr>
                     </thead>
                     <tbody>
                        {this.props.devices.map((d,i)=>(
                            <tr className="deviceRow" key={"devvss_" + i} value={i} onClick={(e)=>window.app.selectDevice(d)}>
                                {/* <td>{i}</td> */}
                                <td>{d.online===0 ? <span className="red">⬤</span> : <span className="dark">⬤</span>}</td>
                                <td>{d.name}</td>
                                <td>{d.model}</td>
                                <td>{d.serial}</td>
                                {/* <td>{d.extTemp}</td>
                                <td>{d.intTemp}</td> */}
                                <td>{d.address}</td>
                                <td>{d.city}</td>
                                <td>{d.lastTimeData===0 ? "" : msToTime(Date.now()-d.lastTimeData)}</td>
                                {/* <td>link</td> */}
                            </tr>
                        ))}
                     </tbody>
                 </table>

            </>
    }
}
export default NetworkStatus;