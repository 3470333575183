import React from 'react';
//import { Link } from "react-router-dom";

class Support extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    render () {
        return <>
                <h1>Support</h1>
                <h2>Q & A Infinitus Support</h2>
                <>
                    Education is key and time is money. Infinitus is here to help!
                    We have prepared a list of questions regarding maintenance and technical issues. Let this instructions help you to fix the problem yourself, or just to ask the right questions to Infinitus support.

                    <h3>ControlMotion Issues</h3>
                    <h3>LCD Issues</h3>
                    <h3>Heater Issues</h3>
                    <h3>Power Supply Issues</h3>
                    <h3>Wi-Fi Signal Issues</h3>
                    <h3>imotion G7 / G6 Issues</h3>
                    <h3>imotion G7 / G6 Issues</h3>
                    <h3>imotion FLOW Questions</h3>
                    <h3>imotion LITE Questions</h3>
                </>
              </>
    }
}
export default Support;