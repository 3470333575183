/*

import { Link,withRouter } from "react-router-dom";
import GoogleMap from "react-google-maps"; 
import Marker from "react-google-maps"; 


const DisplayGMap = (props) =>
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: -34.397, lng: 150.644 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
  </GoogleMap>

//<MyMapComponent isMarkerShown />// Map with a Marker
//<MyMapComponent isMarkerShown={false} />// Just only Map

export default withRouter(DisplayGMap);
*/

import React, { Component, withRouter } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

let key = 'AIzaSyDeR1YKohiDWegI0SF0mmSZI9j8xj7TF5o';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

class DisplayGMap extends Component {
  onLoad(marker) {
    console.log('marker: ', marker)
  }

  render() {
    return <LoadScript
          googleMapsApiKey={key}>
         <GoogleMap
           mapContainerStyle={containerStyle}
           center={this.props.center}
           zoom={13}
         >
           <Marker
              onLoad={this.onLoad}
              position={this.props.position}
            />
           <></>
         </GoogleMap>
       </LoadScript>
  }
}

export default DisplayGMap;

// 
// { /* Child components, such as markers, info windows, etc. */ }