import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link,withRouter } from "react-router-dom";
import ReactDom from 'react-dom';

var md = `
# Partner Access

WIP 
`;



class PartnerAccess extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        selection:[]
      };
    }

    render() {
        // return <ReactMarkdown children={md} remarkPlugins={[remarkGfm]} /> 
        return  <div 
                    dangerouslySetInnerHTML={{ 
                      __html: "<iframe class='embeddedcontainer' src='https://controlmotion-iot.com/partners/Support/SupportPage.php' />"}} />

        //    <iframe src="https://controlmotion-iot.com/partners/Support/SupportPage.php" />
        //   </div>
        // </div> 
        // className='embeddedcontainer'
    }
}
export default withRouter(PartnerAccess);