import React from 'react';
import { Link,withRouter } from "react-router-dom";
import * as utils from './utils.js'; 

const imotionG6KeyNameValue = {
    "cmcuG6Id" : { name: "id", symbol: null},
    "airPressureDrop" : { name: "Air Pressure Drop", symbol: "Pa", valueMap:{"0":"Clean","1":"Clogged"} },
    "alarmCompressorProtection" : { name: "Alarm Compressor Protection", symbol:"", valueMap:{"0":"Ok","1":"Error"}},
    "alarmCondenserDirty" : { name: "Alarm Condenser Dirty", symbol:""},
    "alarmCondenserFanProtection" : { name: "Alarm Condenser Fan Protection", symbol:"", valueMap:{"0":"Ok","1":"Error"}},
    "alarmEvaporatorFanProtection" : { name: "Alarm Evaporator Fan Protection", symbol:"", valueMap:{"0":"Ok","1":"Error"}},
    "alarmHeaterProtection" : { name: "Alarm Heater Protection", symbol:"", valueMap:{"0":"Ok","1":"Error"}},
    "alarmReset" : { name: "Alarm Reset", symbol:""},
    "compressor" : { name: "Compressor", symbol:"%"},
    "compressorPower" : { name: "Compressor Power", symbol:"kW"},
    "compressorSpeed" : { name: "Compressor Speed", symbol:"rpm"},
    "condenserFan" : { name: "Condenser Fan", symbol:"rpm"},
    "condenserPressure" : { name: "Condenser Pressure", symbol:"Pa"},
    "discargeHVACGasTemperature" : { name: "Discarge HVAC Gas Temperature", symbol:"°C"},
    "suctionHVACGasTemperature" : { name: "Suction HVAC Gas Temperature", symbol:"°C"},
    "enclosureOpened" : { name: "Enclosure Opened", symbol:"", valueMap:{"0":"Closed","1":"Opened"} },
    "evaporatorPressure" : { name: "Evaporator Pressure", symbol:"Pa"},
    "exhaustTemperature" : { name: "Exhaust Temperature", symbol:"°C"},
    "filterClogged" : { name: "Filter Clogged", symbol:""},
    "frontDoor" : { name: "Front Door", symbol:"", valueMap:{"0":"Closed","1":"Opened"}},
    "heater" : { name: "Heater", symbol:"", valueMap:{"0":"Off","1":"On"}},
    "intakeFrontLCD" : { name: "Intake Front LCD", symbol:""},
    "intakeRearLCD" : { name: "Intake Rear LCD", symbol:""},
    "intakeTemperature" : { name: "Intake Temperature", symbol:"°C"},
    "internalTemperatures" : { name: "Internal Temperatures", symbol:"°C"},
    "inverter" : { name: "Inverter", symbol:"", valueMap:{"0":"On","1":"Off"}},
    "inverterCommunication" : { name: "Inverter Communication", symbol:"", valueMap:{"0":"Ok","1":"Error"}},
    "inverterDCVoltage" : { name: "Inverter DC Voltage", symbol:"V"},
    "inverterError" : { name: "Inverter Error", symbol:"", valueMap:{"0":"Ok","1":"Error"}},
    "inverterErrorCode" : { name: "Inverter Error Code", symbol:""},
    "inverterReady" : { name: "Inverter Ready", symbol:"", valueMap:{"0":"Ready","1":"Offline"}},
    "inverterTemperature" : { name: "Inverter Temperature", symbol:"°C"},
    "rearDoor" : { name: "Rear Door", symbol:"", valueMap:{"0":"Closed","1":"Opened"}},
    "linkG6DataId" : { name: "linkG6DataId", symbol:null},
    "created": { name: "created", symbol: null},
    "modified" : { name: "modified", symbol: null},
} 

class DeviceViewIMotionG6 extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        selection:[]
      };
    }
    componentDidMount()
    {
       // if(window.app.state.comm!=null)
       //    window.app.state.comm.addToGroup(this, this.props.match.params.id);
    }

    toPropView(propName, symbol, title)
    {
        //console.log( this.props);
        window.app.setGraphData(symbol,title);
        this.props.history.push("/devicegraph/"+this.props.d.deviceId+"/"+propName); //+"/"+encodeURIComponent(symbol)+"/"+encodeURIComponent(name));
    }
    toMultiPropView(propName, symbol, title)
    {
        //console.log( this.props);
        window.app.setGraphData(symbol,"Viewing " + title);
        var ns = "";
        for(var s of this.state.selection)
           ns = ns + "," + s.key;

        ns = ns.substring(1);
        window.app.setGraphData(symbol, ns);

        this.props.history.push("/devicemultigraph/"+this.props.d.deviceId+"/"+ns); //+"/"+encodeURIComponent(symbol)+"/"+encodeURIComponent(name));
    }

    onCheckboxClick(key,select,event,d)
    {
        //console.log(key, select, event, d);
        var newSelection = this.state.selection;

        for (var i = newSelection.length - 1; i >= 0; --i) {
            if (newSelection[i].key === key) {
                newSelection.splice(i,1);
                console.log("removed", key);
                return; // nothing to do
            }
        }
        
        newSelection.push({key:key, symbol:d.symbol, name:d.name});
        this.setState({selection:newSelection});
    }

    render () {
        var d = this.props.data; 
        if(d===null || d===undefined) return null; 
        var device = this.props.d; 
        var t = 0;
        var red=[235,0,0];
        var green=[0,255,0];
        var newColor = "rgba(255,0,0,1)";

        var renderbase = Object.entries(d).map(([key, value]) => {
            // Pretty straightforward - use key for the key and value for the value.
            // Just to clarify: unlike object destructuring, the parameter names don't matter here.
            var d = imotionG6KeyNameValue[key];
            if(d!==undefined && d.symbol!=null)
            {
                if (device.lastTimeDataProperty===undefined || device.lastTimeDataProperty[key]===undefined) 
                {
                    t=0;
                }
                else 
                {
                    t = 1-utils.msToTime(device.lastTimeData-device.lastTimeDataProperty[key])/240.0; // decay 4 minutes
                }
                var c = utils.interpolateColor(red,green,t);
                newColor = "rgba("+c[0]+","+c[1]+","+c[2]+")";
                // return <>
                //            <div className="infoSide textRight"> {d.name} </div>
                //            <div className="sensorSide"> {value} {d.symbol} </div>
                //         </>
                       
                return <tr key={"dvig6_"+key}>
                             <td><input type="checkbox" onClick={(event)=>this.onCheckboxClick(key,value,event,d)}/></td>
                             <td  className="textLeft">{d.name}</td>
                             <td/>
                             {/* {d.valueMap===undefined ?
                             <td  className="textLeft">{value} {d.symbol}</td>
                             :
                             <td  className="textLeft">{d.valueMap[value]}</td>
                             } */}
                             {/* <td>{d.isAlarm===0 ? <span className="red">⬤</span> : <span className="dark">⬤</span>}</td>  */}

                             {d.valueMap===undefined ?
                             <td  className="textLeft">{value} {d.symbol}</td>
                             :
                             <td  className="textLeft">{d.valueMap[value]} <small><small>({value})</small></small></td>
                             }

                             <td><span style={{color:newColor}}>⬤</span></td> 
                             {this.state.selection.length===0 ?
                             <td onClick={(event)=>this.toPropView(key,d.symbol,d.name)}>📈</td>
                             :
                             <td onClick={(event)=>this.toMultiPropView(key,d.symbol, d.name)}>📊</td>
                             }
                       </tr>
            }
        })

        return <table>
                    <thead>
                        <tr>
                            <th width="10px">&nbsp; </th>
                            <th className="textLeft width60">SENSOR</th>
                            <th>&nbsp; </th>
                            <th className="textLeft width30">DATA </th>
                            <th className="width5">&nbsp; </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderbase}
                    </tbody>
                </table>
    }
}
export default withRouter(DeviceViewIMotionG6);