
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link,withRouter } from "react-router-dom";
import ReactDom from 'react-dom';

var md = `
# Terms & Conditions
## Informing and communicating with clients / subscribers
The company will contact the customer / subscriber through a specific communication media, if he gives consent. By completing the form, the customer / subscriber agrees to receive advertising e-mails, a consent is given to process the following data:
Name and surname,
E-mail address,
ID (Google analytics)

## Advertising emails will contain the following components
And will be clearly and unambiguously marked as advertising messages.
The sender will be clearly evident.
Various campaigns, promotions and other marketing techniques will be designated as such.
The way to unsubscribe from receiving ad messages will be visually presented.
The sender will respect the user not wanting to receive advertising messages.


## When registering to receive advertising emails, a user may authorize the use of his personal information for the following purposes:
[1] (OPTIONAL) Consent for tracking activities and interests on the https://www.controlmotion-iot.com website and all associated sub-domains:
- Export, storage and processing of data in the marketing automation tool, which helps us to keep track of your activities on the website https://www.controlmotion-iot.com and all associated sub-domains. It enables us to perform ordered services, contact regarding your activities on the web pages and aggregation and anonymized analysis of the use of the website and its content.
- All personal data are used exclusively for the purpose of providing our services, anonymised analysis and optimizing our operations and are not available to the public, unless the user explicitly gives consent.

[2] (MANDATORY) Consent to receive news about Infinitus d.o.o. offers and other innovations apply until cancellation and include:
- using data to send regular e-news about our offer and news.
- the use of data for occasional delivery of other content, such as ecommerce guides, ecommerce event notifications and such

Exporting, storing and processing data in a marketing automation tool that allows us to send these kind of news
data processing with pseudonymization (so that it can not be attributed to a specific individual)

[3] (OPTIONAL) The consent to receive personalized news and offers is valid until canceled and includes:
- use of data for direct marketing
- Re-targeting platforms to reintroduce products / offers
- export and processing of data in digital advertising tools (use of e-mail for the preparation of anonymous user segments, in this case the e-mail cannot be attributed to a specific individual)
- data processing with pseudonymization (so that it cannot be attributed to a specific individual)

`;



class CookiesPolicy extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        selection:[]
      };
    }

    render() {
        return <ReactMarkdown children={md} remarkPlugins={[remarkGfm]} /> 
    }
}
export default withRouter(CookiesPolicy);