import React from 'react';
import { Redirect, Link, withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ResponsiveLine, Line, LineCanvas } from '@nivo/line';
import { ResponsiveLineCanvas } from '@nivo/line';
import { Wrapper } from '@nivo/core'

const data = [
    {
      "id": "Internal temperature",
      "color": "hsl(313, 70%, 50%)",
      "data": [
        {
          "x": "637575308794778299",
          "y": 296
        },
        {
          "x": "637572761201066173",
          "y": 288
        }
      ]
    } 
  ]; 

 function convertTime (formated) 
 {
    //const formated_Date = '2017-07-30T15:01:13Z';
    const date = new Date(formated) // formated_Date - SDK returned date
    
    //console.log(`${date.getDate()}, ${date.getMonth() +1 }, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`)
    return ""+ date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
 }

const MyResponsiveLine = ({ data, title, symbol /* see data tab */ }) => (
    
    // ResponsiveLine
    // <Wrapper>
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        // xScale={{ type: 'point' }}
        xScale={{
            type: 'time',
            format: "%Y-%m-%dT%H:%M:%S.%f%Z", //'%Y-%m-%d',
            //format: "%Y-%m-%dT%H:%M:%S.%L%Z", //'%Y-%m-%d',
            //useUTC: false,
            //format: '%HH-%MM',
            //precision: 'day',
            }}
        //xFormat="time:%Y-%m-%dT%H:%M:%S.%L%Z"
        xFormat="time:%Y-%m-%dT%H:%M:%S.%f%Z"
        yScale={{ type: 'linear', min: 'auto', max: 'auto', 
                  stacked: false,
                  reverse: false }}

        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendOffset: 36,
            legendPosition: 'middle',
            format: '%b %d',
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: symbol, //'Temp',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        
        // tooltip={({ x, y }) => (
        //      <strong >
        //          {x}:{y} 
        //      </strong>
        // )}
        theme={{
            tooltip: {
                container: {
                    background: '#3333',
                },
            },
        }}
        colors={{ scheme: "spectral" }}
        enablePoints={false}
        pointSize={2}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        curve="monotoneX"
        //curve={select('curve', curveOptions, 'monotoneX')}
        useMesh={true}
        enableArea={false}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
            return (
                <div
                    style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                    }}
                >
                    {/* <div>{JSON.stringify(slice)} x: {slice.id}</div> */}
                    {/* <div>x: {slice.id}</div>    */}

                    {slice.points.map(point => (
                        <div
                            key={point.id}
                            style={{
                                color: point.serieColor,
                                padding: '3px 0',
                            }}>

                          <strong>{point.serieId}</strong> {point.data.yFormatted} <small>[{convertTime(point.data.xFormatted)}]</small> 
                            
                        </div>
                    ))}
                </div>
            )
        }}
    />
    // </Wrapper>
)

class DeviceViewPropertyGraph extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          enabled: false,
          nodata: false,
          data: null,
          status: "",
          properties: [],
          startTime: (Date.now() - 86400000), // since yesterday
          endTime: Date.now() - 10// till now -10s
      };
      // //617690224196024400,
      this.getDataSingle = this.getDataSingle.bind(this);
      this.getDataMultiple = this.getDataMultiple.bind(this);
    }
    async componentDidMount()
    {
        //if(window.app.state.comm!=null)
        //   window.app.state.comm.addToGroup(this, this.props.match.params.id);
        var incomingProperties = this.props.match.params.propName;
        //if(incomingProperties==undefined) // split 
        await this.setState({properties: (""+incomingProperties).split(",")}); 
        //console.log(this.state.properties);


        console.log("propertyGraph", this.props.match.params.id, this.props.match.params.propName, this.state.properties);
        if(this.state.properties.length<=1)
        {
           this.getDataSingle();
           this.intervalHandle = setInterval(this.getDataSingle, 60 * 10000); // every n s
        }
        else 
        {
            this.getDataMultiple();
            this.intervalHandle = setInterval(this.getDataMultiple, 2 * 60 * 1000); // every n s
        }
    }
    componentWillUnmount() { 
        clearInterval(this.intervalHandle); 
    }
    async getDataMultiple() {
        this.setState({enabled:false});
        this.setState({status:"fetching"});

        const start = this.dateToTicks(new Date(this.state.startTime));
        const end = this.dateToTicks(new Date(this.state.endTime));
        this.setState({data:null});
        this.setState({nodata:false}); 
        
        var url = 'https://iotsihelpi.azurewebsites.net/api/Device/GetGraphHistoryForDeviceProperties/'+this.props.match.params.id+"/"+this.props.match.params.propName+"/"+start+"/"+end;

        console.log("start", start);
        console.log("end", end);
        console.log("url", url);

        const response = await fetch(url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                //data: this.state.properties,
                method: "GET",
            }
        );

        if(response.status===200)
        {
            this.setState({status:"converting"});
            const data = await response.json(); // waits until the request completes...
            console.log("incoming" , data);
            var newData = [];
            for(var d=0;d<data.length;d++)
            {
                console.log("processing" , d, data[d].name, data[d].ticks);
                this.setState({status:"processing " + data[d].name});
                var convert = this.convert(data[d].ticks); //window.app.displayToast("Data received");    
                newData.push(
                    {
                        "id": data[d].name, 
                        //"color": "rgb(56, 256, 56)",
                        "data": convert
                     }
                )
                console.log("newData" , newData);
                this.setState({status:"converted " + data[d].name});
            }
            this.setState({data:newData});
            this.setState({status:"rendering"});
            
        } else {
            window.app.displayToast("Failed to get data for " + this.props.match.params.propName);
            this.setState({status:"failure converting"});
        }
    }

    async getDataSingle() {
        console.log("single data");
        this.setState({enabled:false});
        const response = await fetch('https://iotsihelpi.azurewebsites.net/api/Device/GetGraphHistoryForDeviceProperty/'+this.props.match.params.id+"/"+this.props.match.params.propName,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "GET",
            }
        );

        if(response.status===200)
        {
            const data = await response.json(); // waits until the request completes...
            //console.log(data);
            var convert = this.convert(data); //window.app.displayToast("Data received");
            this.setState({data:[
                {
                  "id": this.props.title, //this.props.match.params.title,//propName,
                  "color": "rgb(56, 256, 56)",//"hsl(313, 70%, 50%)",
                  "data": convert
                }/*,
                {
                  "id": "this.props.title", //this.props.match.params.title,//propName,
                  "color": "rgb(6, 256, 56)",//"hsl(313, 70%, 50%)",
                  "data": convert
                }*/
                ]
            });
            
        } else {
            window.app.displayToast("Failed to get data for " + this.props.match.params.propName);
        }
    }
    

    convert(data)
    {
        if(data.length===0) this.setState({nodata:true}); 

        var c = []; 
        for(var i=0;i<data.length;i++)
        {
            //console.log(data[i].x);
            c.push({x:this.ticksToTime(data[i].x), y:data[i].y});
        }
        return c;
    }

    ticksToTime(ticks)
    {
        var epochTicks = 621355968000000000;
        var ticksPerMillisecond = 10000; 
        var ticksSinceEpoch = ticks - epochTicks;
        var millisecondsSinceEpoch = ticksSinceEpoch / ticksPerMillisecond;

        var date = new Date(millisecondsSinceEpoch);
        //var d = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDay();
        //console.log(ticks,date.toISOString());
        return date.toISOString();
    }
    dateToTicks(date) {
        const epochOffset = 621355968000000000;
        const ticksPerMillisecond = 10000;
        const ticks = date.getTime() * ticksPerMillisecond + epochOffset;
        return ticks;
    }
    onChangeStartDate(date)
    {
        const parsed = Date.parse(date);
        const ticks = this.dateToTicks(new Date(parsed));
        this.setState({startTime:parsed});
        this.state.startTime = parsed;
        //this.state.endTime = parsed+86400000; // ticks in day
        
        console.log("startTime", parsed);
        //console.log("date", date);
        //console.log("ticks", ticks); // ticks go to server
        
        //this.getDataMultiple();
    }
    onChangeEndDate(date)
    {
        const parsed = Date.parse(date);
        const ticks = this.dateToTicks(new Date(parsed));
        // this.setState({startTime:parsed});
        // this.state.startTime = parsed;
        this.setState({endTime:parsed});
        this.state.endTime = parsed; // ticks in day
        console.log("endTime", parsed);
        
        //console.log("parsed", parsed);
        //console.log("date", date);
        //console.log("ticks", ticks); // ticks go to server
        
        //this.getDataMultiple();
    }

    render () {
        if(this.props.d===null) { return <div><h3>Invalid profile</h3> <Redirect push to="/login" /> </div> } 

        var graph = <h4>Please wait... {this.state.status}</h4>;
        if(this.state.data!==null) graph= <MyResponsiveLine data={this.state.data} title={this.props.title} symbol={this.props.symbol}/>

        return <>
           <h4>{this.props.d.name} </h4>
           <h2>{this.props.title} </h2> 

           <div className="inlineDiv">
           Start: <DatePicker selected={this.state.startTime} onChange={(date) => this.onChangeStartDate(date)} />
           End: <DatePicker selected={this.state.endTime} onChange={(date) => this.onChangeEndDate(date)} />
           <div className="darker strong" onClick={(e)=>this.getDataMultiple()}>Go</div>
           </div>
           
           
           

           {this.state.nodata===true  ? <h3 className="red">No history </h3> : null}   

           <div className="graph-main">
                {graph}
            </div>
            {/* <div>
              {new Date(this.state.startTime).toString()}
            </div> */}
        </>
    }
}
export default withRouter(DeviceViewPropertyGraph);