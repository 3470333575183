import React from 'react';

class DeviceViewBasicInfo extends React.Component {
    constructor(props){
      super(props);
      this.state = {
         currentView:"Diagnostic"
      };
    }
    componentDidMount()
    {
       // if(window.app.state.comm!=null)
       //    window.app.state.comm.addToGroup(this, this.props.match.params.id);
    }
    
    render () {
        var d = this.props.d; 
        if(d===null) return null; 

        return  <>
                    <div className="infoSide textLeft">
                        <span className="textRight bold">Serial       </span>  <br/>
                        <span className="textRight bold">Model        </span>  <br/>
                        <span className="textRight bold">Brand        </span>  <br/>
                        <span className="textRight bold">Name         </span>  <br/>
                        <span className="textRight bold">Description  </span>  <br/>
                        <span className="textRight bold">Address      </span>  <br/>
                        <span className="textRight bold">Location     </span>  <br/>
                    </div>
                    <div className="rightSide">
                        <span className="textLeft">{d.serial}         </span>  <br/>
                        <span className="textLeft">{d.model}          </span>  <br/>  
                        <span className="textLeft">{d.brand}          </span>  <br/> 
                        <span className="textLeft">{d.name}           </span>  <br/> 
                        <span className="textLeft">{d.description}    </span>  <br/> 
                        <span className="textLeft">{d.compactAddress} </span>  <br/>
                        <span className="textLeft">{d.coordinate.latitude},{d.coordinate.longitude} </span>  <br/><br/>
                    </div>
                </>
    }
}

export default DeviceViewBasicInfo;