import React from 'react';
//import { Link } from "react-router-dom";

class UserLogin extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          enabled: true,
          user: localStorage.getItem("user"), 
          //user: localStorage.getItem("user"), 
          //pass: "Vabilo9vabilo9!" 
          pass: "" 
      };
    }
    hUser(e) { this.setState({ user: e.target.value }); }
    hPass(e) { this.setState({ pass: e.target.value }); }    

    async Login() {
        this.setState({enabled:false});
        localStorage.setItem("user", this.state.user); 
        //localStorage.setItem("password", state.pass); 

        await window.app.Login(this.state.user, this.state.pass);
        this.setState({enabled:true});
    }

    
    Cancel() {
        window.app.setState({redirectToHome: true});
    }

    render () {
        return <>
                <div className="settingsWindow"> 
                  <h2 className="menu-title">Account details</h2>

                  <div>Please enter following details</div> <br/>
                  User Name<br/>
                  <input type="text" placeholder="Username"  value={this.state.user} onChange={(e)=>this.hUser(e)} className="inputBox"/><br/>
                  Password <br/>
                  <input type="password" placeholder="Password" value={this.state.pass} onChange={(e)=>this.hPass(e)} className="inputBox"/><br/>

                  {this.state.enabled===false ? <div className="settingsWindow">
                                                    <h2 className="menu-title">ControlMotion IOT</h2>          
                                                    <h3 className="menu-title">Signing in</h3> 
                                                    <h4 className="menu-title">Please wait ...</h4> 
                                                </div> : null }

                  <br/>
                  {this.state.enabled===true ? <button onClick={() => this.Login()}>Sign In</button> : null }
                   <br/>
                  {this.state.enabled===true ? <button onClick={() => this.Cancel()}>Cancel</button> : null }
            </div>            
            </>
    }
}
export default UserLogin;