import React from 'react'
// import './effectcss/cracktext.css'; 
const Notfound = () => <div className="viewExchange">
    <h1><br/>There seems to be a problem</h1>
    <span style={{fontSize:"15vmin"}}><br/></span>
    <div className="bodyCrack">
        <div className="header1" data-text="404" ><span>404</span></div>
    </div>
    <div style={{fontSize:"2vmin"}}>Page not found</div>
</div>
export default Notfound;