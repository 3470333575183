import React from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import DeviceViewShort from "./DeviceViewShort.js"; 

class DevicesListShort extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }

    toDevice(d)
    {
        console.log(d.target.value, this.props);
        if(d.target.value!==-1)
        this.props.history.push("/device/"+this.props.devices[d.target.value].deviceId);
    }
    render () {
      var devices = this.props.devices;
      if(devices===null || devices.length===0) { return <strong>No devices</strong> } 
      

      return <span>
                  <strong>Devices: </strong>{devices.length} &nbsp;
                  {/* {devices.map((d,i)=>(
                    <span key={"devvs_" + i} >
                       <DeviceViewShort device={d} idx={i}/>  
                    </span>
                  ))} */}
                  {/* <div className="custom-select" classNamePrefix="custom-select" style={{width: "200px"}}> */}
                    <select onChange={(e)=>this.toDevice(e)} >
                        <option value="-1" className="dark">View Device</option>
                        {devices.map((d,i)=>(
                            <option  key={"devvss_" + i} value={i}>
                                    {d.name}  
                            </option>
                        ))}
                    </select>
                  {/* </div> */}
             </span>
  }
}
export default withRouter(DevicesListShort);