import React from 'react';
//import { Link } from "react-router-dom";

class ViewDisplayControl extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    render () {
        return <>ViewDisplayControl</>
    }
}
export default ViewDisplayControl;