/*import React from 'react';
//import { Link } from "react-router-dom";

class PrivacyPolicy extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    render () {
        return <>
                <h1>Support</h1>
                <h2>Q & A Infinitus Support</h2>
                <>
                    Education is key and time is money. Infinitus is here to help!
                    We have prepared a list of questions regarding maintenance and technical issues. Let this instructions help you to fix the problem yourself, or just to ask the right questions to Infinitus support.

                    <h3>ControlMotion Issues</h3>
                    <h3>LCD Issues</h3>
                    <h3>Heater Issues</h3>
                    <h3>Power Supply Issues</h3>
                    <h3>Wi-Fi Signal Issues</h3>
                    <h3>imotion G7 / G6 Issues</h3>
                    <h3>imotion G7 / G6 Issues</h3>
                    <h3>imotion FLOW Questions</h3>
                    <h3>imotion LITE Questions</h3>
                </>
              </>
    }
}
export default PrivacyPolicy;*/

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link,withRouter } from "react-router-dom";
import ReactDom from 'react-dom';

var md = `
# Privacy Policy
## Data collecting
Our company respects privacy, so we handle customer data responsibly and in accordance with the law (Personal Data Protection Act, Consumer Protection Act, Electronic Communications Act and the General Data Protection Regulation of the EU).
Personal data is used solely for the smooth operation of the company.
Customers who will give consent to the processing of personal data will be informed about the purpose of using the given personal data and the rights set by the legislation.
The controller of the personal data you provided is company INFINITUS, digitalno video komuniciranje, d.o.o., Prevale 7, Trzin, 1236 Trzin, Slovenia, registration number.: 2009188000, tel. No. 015128052, fax 05/720-3555, e-mail: info@infinitus-outdoor.com.
Your personal data will be processed on the basis of Article 6, paragraph 1, of theRegulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation). The users of personal data are the internal departments of company INFINITUS d.o.o. and its contractual processors with whom it has concluded an appropriate contract for the processing of personal data. Personal data is stored for the duration of the contractual relationship or for a limited period of time, if it is determined by the law or is such a legitimate interest in INFINITUS d.o.o.. In accordance with the fifth paragraph of Article 86 of the Value Added Tax Act, ZDDV-1, we are obliged to store the invoices for 10 years.

## User rights
The user of the website can tell the company INFINITUS d.o.o. (the controller of the personal data) to stop using his personal information via the following email address: info@infinitus-outdoor.com
The user of the website has the right to access their personal data, which were acquired during the use of the page. The personal data manager must first check the identity of the person requesting access to personal data.
The user of the website has the right to correct inaccurate data that were obtained acquired during the use of the page. The data controller must first verify the identity of the person who requests the correction of inaccurate personal data.

The user of the website has the right that his personal data is forgotten (the erasure of personal data) as specified by an article 17 of General data protection regulation.
The user of the website has the right to terminate the use of personal data for direct marketing and profiling.
The user of the website has the right to decide on the use of personal data based on the automated processing.

The user of the website has the right to appeal against the controller of personal data to the supervisory authority if he considers that the processing of his personal data violates the GDPR.


## Protecting privacy
I am aware that all the above requirements regarding the exercise of rights in connection with personal data can be addressed to the controller using the following email address: info@infinitus-outdoor.com
I am aware that in the case of exercise of these rights the operator may request additional information for the needs of reliable identification. He can only refuse to act if he proves that he cannot reliably identify the person.
I am aware that, upon my request, with which I exercise my rights in connection with the above personal data, the operator must reply without undue delay and no later than one month after the receival of the request.


## Procedure for exercising rights
For company INFINITUS d.o.o. and its webpage https://www.controlmotion-iot.com, all received personal data of customers and users of a website (such as name, surname, e-mail address, telephone number, ID-Google analytics) are private and confidential.
Personal data is stored in accordance with the law and is accessible only to authorized personnel and to the person who owns them.
Personal data is used exclusively for the purposes of product marketing. Company INFINITUS d.o.o. won’t provide personal data to third parties without the consent of the client.

## Data protection
Customer data is stored in digital form, and processed and protected in accordance with the law. We need customer personal information to complete the order and ship the product. All personal information will be stored discretely and we won’t forward them to third parties.

## Invoice Archive
The company will send you an order confirmation via e-mail. The invoice is issued at the time of the acceptance of shipment. All documents (records of purchases, orders, invoices, complaints) are kept at the office of the company INFINITUS d.o.o, digitalno video komuniciranje, d.o.o., Prevale 7, Trzin, 1236 Trzin, Slovenia. A registered customer may request copies of documents to be transmitted electronically or in paper form.

If you do not provide personal information, the contract or pre-contract cannot be implemented. We do not make automated decisions as described in the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the Protection of Individuals with regard to Automatic Processing of Personal Data and on the free movement of such data and we do not design profiles.

`;



class PrivacyPolicy extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        selection:[]
      };
    }

    render() {
        return <ReactMarkdown children={md} remarkPlugins={[remarkGfm]} /> 
    }
}
export default withRouter(PrivacyPolicy);