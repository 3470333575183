import React from 'react';
import { Link, withRouter } from "react-router-dom";
import * as utils from './utils.js'; 

// check changes with db types 
const imotionFlowKeyNameValue = {
    "airFilter" : { name: "Air Filter", symbol:"", valueMap:{"1":"Clean","0":"Clogged"}},
    "cmcufLowId" : { name: "cmcufLowId", symbol:null},
    "enclosure" : { name: "Enclosure", symbol:"", valueMap:{"0":"Closed","1":"Opened"}},
    "externalTemperature" : { name: "External Temperature", symbol:"°C"},
    "fan1RPM" : { name: "Fan 1", symbol:"RPM"},
    "fan2RPM" : { name: "Fan 2", symbol:"RPM"},
    "fan3RPM" : { name: "Fan 3", symbol:"RPM"},
    "fan4RPM" : { name: "Fan 4", symbol:"RPM"},
    "heater" : { name: "Heater", symbol:"", valueMap:{"1":"On","0":"Off"}},
    "internalTemperature" : { name: "Internal Temperature", symbol:"°C"},
    "linkFlowDataId" : { name: "linkFlowDataId", symbol:null},
    "optDefibCompartment" : { name: "Defib Compartment", symbol:"", valueMap:{"1":"Present","0":"N/A"} },
    "optDefibPresent" : { name: "Defib Present", symbol:"", valueMap:{"1":"Opened","0":"N/A","2":"OK"} },
    "optShockSensor" : { name: "Shock Sensor", symbol:"", valueMap:{"1":"OK","0":"N/A","2":"Triggered"} },
    "created": { name: "created", symbol: null},
    "modified" : { name: "modified", symbol: null},
} 

class DeviceViewIMotionFlow extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        selection:[]
      };
    }
    componentDidMount()
    {
       // if(window.app.state.comm!=null)
       //    window.app.state.comm.addToGroup(this, this.props.match.params.id);
    }
    toPropView(propName, symbol, title)
    {
        //console.log( this.props);
        window.app.setGraphData(symbol,title);
        this.props.history.push("/devicegraph/"+this.props.d.deviceId+"/"+propName); //+"/"+encodeURIComponent(symbol)+"/"+encodeURIComponent(name));
    }
    toMultiPropView(propName, symbol, title)
    {
        //console.log( this.props);
        window.app.setGraphData(symbol,"Viewing " + title);
        var ns = "";
        for(var s of this.state.selection)
           ns = ns + "," + s.key;

        ns = ns.substring(1);
        window.app.setGraphData(symbol, ns);

        this.props.history.push("/devicemultigraph/"+this.props.d.deviceId+"/"+ns); //+"/"+encodeURIComponent(symbol)+"/"+encodeURIComponent(name));
    }
    onCheckboxClick(key,select,event,d)
    {
        //console.log(key, select, event, d);
        var newSelection = this.state.selection;

        for (var i = newSelection.length - 1; i >= 0; --i) {
            if (newSelection[i].key === key) {
                newSelection.splice(i,1);
                console.log("removed", key);
                return; // nothing to do
            }
        }
        
        newSelection.push({key:key, symbol:d.symbol, name:d.name});
        this.setState({selection:newSelection});
    }


    render () {
        var d = this.props.data; 
        var device = this.props.d; 
        var t = 0;
        var red=[235,0,0];
        var green=[0,255,0];
        var newColor = "rgba(255,0,0,1)";

        if(d===null || d===undefined) return null; 

        var renderbase = Object.entries(d).map(([key, value]) => {
            // Pretty straightforward - use key for the key and value for the value.
            // Just to clarify: unlike object destructuring, the parameter names don't matter here.
            var d = imotionFlowKeyNameValue[key];
            if(d!==undefined && d.symbol!=null)
            {
                if (device.lastTimeDataProperty===undefined || device.lastTimeDataProperty[key]===undefined) 
                {
                    t=0;
                }
                else 
                {
                    t = 1-utils.msToTime(device.lastTimeData-device.lastTimeDataProperty[key])/240.0; // decay 4 minutes
                }
                var c = utils.interpolateColor(red,green,t);
                newColor = "rgba("+c[0]+","+c[1]+","+c[2]+")";
                // return <>
                //            <div className="infoSide textRight"> {d.name} </div>
                //            <div className="sensorSide"> {value} {d.symbol} </div>
                //         </>
                       
                //return <tr key={"dvif_"+key} onClick={(event)=>this.toPropView(key,d.symbol,d.name)}>
                return <tr key={"dvif_"+key}>
                             <td><input type="checkbox" onClick={(event)=>this.onCheckboxClick(key,value,event,d)}/></td>

                             <td  className="textLeft">{d.name}</td>
                             <td/>
                             {d.valueMap===undefined ?
                             <td  className="textLeft">{value} {d.symbol}</td>
                             :
                             <td  className="textLeft">{d.valueMap[value]} <small><small>({value})</small></small></td>
                             }
                            <td><span style={{color:newColor}}>⬤</span></td> 
                             
                             {this.state.selection.length===0 ?
                             <td onClick={(event)=>this.toPropView(key,d.symbol,d.name)}>📈</td>
                             :
                             <td onClick={(event)=>this.toMultiPropView(key,d.symbol, d.name)}>📊</td>
                            }
                             {/* <td>{d.isAlarm===0 ? <span className="red">⬤</span> : <span className="dark">⬤</span>}</td>  */}
                     </tr>
            }
        })

        return <table>
                    <thead>
                        <tr>
                            <th width="10px">&nbsp; </th>
                            <th className="textLeft width60">FLOW SENSOR</th>
                            <th>&nbsp; </th>
                            <th className="textLeft width30">DATA </th>
                            <th className="width5">&nbsp; </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderbase}
                    </tbody>

                </table>
    }
}
export default withRouter(DeviceViewIMotionFlow);