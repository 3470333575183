import React from 'react';
//import { Link } from "react-router-dom";

class ViewG6 extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    render () {
        return <>ViewG6</>
    }
}
export default ViewG6;