import React from 'react';
import { Link } from "react-router-dom";

class BarTop extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    appLogin() {
        window.app.setState({accessToken:null});
        window.app.setState({redirectToLogin: true});
    }
    render () {
        var logo = <div className="logo">
                    {/* <Link className="menu-a" style={{paddingTop:"15px"}} to="/" title="ControlMotion software reports real time status of the iMotion products">INFINITUS | ControlMotion</Link> */}
                    {/* <img src="/nicons/Infnitus_ControlMotion.svg" /> */}
                    <Link to="/" title="ControlMotion software reports real time status of the iMotion products" style={{width:"13vmin", height:"50%", paddingTop:"0.3hmin"}}><img src="/nicons/Infnitus_ControlMotion.svg" /></Link>
                   </div>

        /*if(this.props.accessToken===null) 
        {
            return  <div className="header">
                        {logo}

                        <div className="menu">
                            <ul className="menu-navigation">
                                <li><Link className="menu-a" to="#" onClick={() => this.appLogin()}>Login</Link></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>

        }*/
        return <div className="header">
                    {logo}

                    {/* <div className="username" >
                        <Link to="/profile" title="User name">User: {this.props.accessToken.userName}</Link>     
                    </div> */}

                    <div className="menu">
                        <ul className="menu-navigation">
                            {this.props.accessToken===null ? <li><Link className="menu-a" to="#" onClick={() => this.appLogin()}>Login</Link></li> :
                            <>
                            <li><Link className="menu-a"  to="/NetworkStatus">Network status</Link></li>
                            <li><Link className="menu-a"  to="/Alarms">Alarms</Link></li>
                            {/* <li><Link to="/MyIMotions">My imotions</Link></li>
                            <li><Link to="/Support">Support</Link></li> */}
                            <li><Link className="menu-a"  to="/profile" title="User name" >User: {this.props.accessToken.userName}</Link></li>     
                            </>
                        }
                        </ul>
                    </div>
               </div>
    }
}
export default BarTop;