import React from 'react';
import { Link } from "react-router-dom";

import DeviceViewBasicInfo from './DeviceViewBasicInfo.js';
import DeviceViewBeagleSensor from './DeviceViewBeagleSensor.js';
import DeviceViewIMotionFlow from './DeviceViewIMotionFlow.js';
import DeviceViewIMotionG6 from './DeviceViewIMotionG6.js';
import DeviceViewDisplayControl from './DeviceViewDisplayControl.js';

import { ResponsiveLine } from '@nivo/line';
const data = [
    {
      "id": "Internal temperature",
      "color": "hsl(313, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 296
        },
        {
          "x": "helicopter",
          "y": 288
        },
        {
          "x": "boat",
          "y": 49
        },
        {
          "x": "train",
          "y": 0
        },
        {
          "x": "subway",
          "y": 71
        },
        {
          "x": "bus",
          "y": 274
        },
        {
          "x": "car",
          "y": 270
        },
        {
          "x": "moto",
          "y": 225
        },
        {
          "x": "bicycle",
          "y": 108
        },
        {
          "x": "horse",
          "y": 180
        },
        {
          "x": "skateboard",
          "y": 78
        },
        {
          "x": "others",
          "y": 279
        }
      ]
    },
    {
      "id": "External temperature",
      "color": "hsl(171, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 68
        },
        {
          "x": "helicopter",
          "y": 219
        },
        {
          "x": "boat",
          "y": 63
        },
        {
          "x": "train",
          "y": 253
        },
        {
          "x": "subway",
          "y": 164
        },
        {
          "x": "bus",
          "y": 187
        },
        {
          "x": "car",
          "y": 110
        },
        {
          "x": "moto",
          "y": 87
        },
        {
          "x": "bicycle",
          "y": 15
        },
        {
          "x": "horse",
          "y": 156
        },
        {
          "x": "skateboard",
          "y": 271
        },
        {
          "x": "others",
          "y": 185
        }
      ]
    },
    {
      "id": "Intake temperature",
      "color": "hsl(263, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 259
        },
        {
          "x": "helicopter",
          "y": 299
        },
        {
          "x": "boat",
          "y": 112
        },
        {
          "x": "train",
          "y": 188
        },
        {
          "x": "subway",
          "y": 275
        },
        {
          "x": "bus",
          "y": 185
        },
        {
          "x": "car",
          "y": 46
        },
        {
          "x": "moto",
          "y": 205
        },
        {
          "x": "bicycle",
          "y": 28
        },
        {
          "x": "horse",
          "y": 12
        },
        {
          "x": "skateboard",
          "y": 85
        },
        {
          "x": "others",
          "y": 106
        }
      ]
    },
    {
      "id": "Inverter temperature",
      "color": "hsl(194, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 275
        },
        {
          "x": "helicopter",
          "y": 202
        },
        {
          "x": "boat",
          "y": 88
        },
        {
          "x": "train",
          "y": 48
        },
        {
          "x": "subway",
          "y": 154
        },
        {
          "x": "bus",
          "y": 235
        },
        {
          "x": "car",
          "y": 217
        },
        {
          "x": "moto",
          "y": 47
        },
        {
          "x": "bicycle",
          "y": 23
        },
        {
          "x": "horse",
          "y": 66
        },
        {
          "x": "skateboard",
          "y": 183
        },
        {
          "x": "others",
          "y": 117
        }
      ]
    },
    {
      "id": "Exhaust temperature",
      "color": "hsl(357, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 276
        },
        {
          "x": "helicopter",
          "y": 192
        },
        {
          "x": "boat",
          "y": 51
        },
        {
          "x": "train",
          "y": 69
        },
        {
          "x": "subway",
          "y": 292
        },
        {
          "x": "bus",
          "y": 128
        },
        {
          "x": "car",
          "y": 232
        },
        {
          "x": "moto",
          "y": 130
        },
        {
          "x": "bicycle",
          "y": 5
        },
        {
          "x": "horse",
          "y": 128
        },
        {
          "x": "skateboard",
          "y": 295
        },
        {
          "x": "others",
          "y": 242
        }
      ]
    }
  ]; 
const MyResponsiveLine = ({ data /* see data tab */ }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Temp',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
)

class ViewDevice extends React.Component {
    constructor(props){
      super(props);
      this.state = {
      };
    }
    componentDidMount()
    {
        if(window.app.state.comm!=null)
           window.app.state.comm.addToGroup(this, this.props.match.params.id);

        var device = window.app.state.devices.find(x=>x.deviceId===this.props.match.params.id);
        window.app.selectDevice(device);
    }

    render () {
        var d = this.props.selectedDevice;
        if(d===null) return null;
        return <>
           <h2>{this.props.match.params.id}</h2>
           <DeviceViewBasicInfo  d={d}/>
           
           <DeviceViewBeagleSensor data={d.beagleSensor} d={d}/> 
           <DeviceViewIMotionFlow data={d.iMotionFlow} d={d}/> 
           <DeviceViewIMotionG6 data={d.iMotionG6} d={d}/> 
           {/* <DeviceViewDisplayControl data={d.displayControl} d={d}/>  */}
           <div className="graph-main">
                 <MyResponsiveLine data={data}/> 
            </div>
        </>
    }
}
export default ViewDevice;